$base-font: Lato;
$text-color-primary: #474747;
$text-color-secondary: #767676;
$text-color-tertiary: #1bace4;

$quote-sent-status-bg-color: #4a90e2;
$quote-in-progress-bg-color: #ff9800;
$quote-won-bgn-color: #32a728;
$border-color: #ededed;

@function rem($size) {
  @return $size / 16px * 1rem;
}

.my-request-quotations {
  font-family: Lato;
  font-style: normal;
  letter-spacing: 0;
  margin-left: 0;

  @media (max-width: 768px) {
    margin-bottom: rem(16px);
    margin-top: rem(16px);
  }

  @media (min-width: 1200px) {
    margin-right: rem(16px);
  }

  .requests_quotations__list {
    margin: 0;
    padding: 0;

    li {
      list-style-type: none;
    }

    .requests_quotations {
      @at-root #{&}__item {
        align-items: flex-start;
        border-bottom: 1px solid $border-color;
        cursor: pointer;
        display: flex;
        padding: rem(16px) 0 rem(16px) rem(16px);
        background-color: white;
      }

      @at-root #{&}__quotation-icon {
        margin-right: rem(16px);
        width: rem(40px);
      }

      @at-root #{&}__address-wrapper {
        width: 100%;
      }

      @at-root #{&}__address {
        margin: 0;
        color: $text-color-primary;
        font-size: rem(14px);
        font-weight: bold;
        line-height: rem(22px);
      }

      @at-root #{&}__quotation-details-wrapper {
        width: 100%;
      }

      @at-root #{&}__product-wrapper {
        display: flex;
        justify-content: space-between;
        margin: 0;
        width: 100%;

        @media (max-width: 768px) {
          flex-wrap: wrap;
          padding-right: 1rem;
        }
      }

      @at-root #{&}__product {
        color: $text-color-primary;
        font-size: rem(14px);
        font-weight: bold;
        line-height: rem(22px);
        margin: 0;
      }

      @at-root #{&}__product-description {
        color: $text-color-secondary;
        font-size: rem(12px);
        font-weight: bold;
        line-height: rem(16px);
        margin: 0;
      }

      @at-root #{&}__category-info {
        color: $text-color-primary;
        padding-left: 0.25rem;
      }

      @at-root #{&}__quantity-wrapper {
        margin: 0;
        font-family: Lato;
        font-size: rem(12px);
        font-style: normal;
        font-weight: 700;
        line-height: rem(16px);
        letter-spacing: 0;
        text-align: left;
        color: $text-color-secondary;
      }

      @at-root #{&}__quantity {
        font-weight: 700;
        color: $text-color-primary;
      }
    }

    .site-item {
      border-bottom: 1px solid $border-color;
      padding: rem(16px) 0 rem(16px) rem(16px);
      display: flex;
      align-items: flex-start;
      background-color: white;
      cursor: pointer;

      .site-img-wrapper {
        border-radius: 50% !important;
        border: 1px solid $border-color;
        background-color: #ededed;
        width: rem(80px);
        height: rem(80px);
        justify-content: center;
        display: flex;
        align-items: flex-end;
        overflow: hidden;
        margin: 0 rem(16px) 0 0;
      }

      .site-img {
        width: rem(40px);
        height: auto;
        margin: 0 rem(16px) 0 0;
      }

      .site-info {
        .site-name {
          font-size: rem(16px);
          font-family: "Lato", sans-serif;
          color: $text-color-primary;
          font-weight: bold;
          text-transform: uppercase;
          line-height: rem(22px);
          margin: 0;
        }

        .site-details {
          font: 0.75rem/1.33 "Lato", sans-serif;
          color: $text-color-secondary;
          padding: 0;
          margin: 0;
          line-height: rem(16px);
          font-weight: bold;
        }
      }
    }
  }
}

.show-mobile {
  .my-request-quotations {
    margin-top: rem(34px);
    @media (min-width: 768px) {
      margin-left: rem(16px);
      margin-right: rem(16px);
    }
  }
}

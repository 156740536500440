.v3-header__container {
  padding: 16px 46px 16px 46px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: white;

  @media screen and (max-width: 768px) {
    padding: 16px;
  }
}

.v3-header__header-wrapper {
  display: flex;
  align-items: flex-start;
}

.v3-header__logo {
  width: 64px;
  height: 64px;
}

.v3-header__title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
}

.v3-header__title {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  margin: 0 0 0 16px;
  color: #474747;

  @media screen and (min-width: 768px) {
    font-size: 24px;
    line-height: 32px;
    margin: 0 0 0 38px;
  }
}

.select-language {
  position: relative;

  .dropdown-toggle::after {
    display: none;
  }

  .bottom-lang {
    margin: 0;
    img {
      height: 32px;
      width: 32px;
    }
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
  }

  .dropdown-menu.show {
    display: block;
    right: 10px;
    text-align: center;
    border-radius: 0;
    padding: 4px 0;
    right: 0;
    padding: 0;
    min-width: auto;
  }

  .dropdown-item {
    margin: 0;
    padding: 0.4rem;
  }
}

.v3-header__step-container {
  max-width: 330px;
  display: flex;
  justify-content: space-between;
  margin: 10px auto 0 auto;

  .rs-progress-line {
    align-items: flex-start;
    margin-top: 6px;
  }
  .rs-progress-line-inner {
    background: #767676;
  }
}

.v3-header__step {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}

.v3-header__step-count {
  background: #767676;
  width: 30px;
  height: 30px;
  color: white;
  border-radius: 50%;
  text-align: center;
  padding: 4px;
  display: inline-block;

  transition: all 0.2s ease-in;

  &.active {
    opacity: 1;
    background: #1bace4;
  }
  &.completed {
    opacity: 0.5;
    background: #1bace4;
  }
  @media screen and (min-width: 768px) {
    width: 40px;
    height: 40px;
    padding: 8px;
  }
}

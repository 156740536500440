.request-view .infinite-scroll-component {
  padding: 4px;
  margin: 0;
  list-style-type: none;
}

.request-view {
  max-height: unset;
  // overflow: hidden;
  #scrollableDiv {
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.request-view {
  max-height: 100vh;
  overflow: auto;
}
.request-view .add-enquiry-btn {
  border: 1px dashed #c2c2c2;
  background-color: #ededed;
}

.add-new-btn-container {
  margin: 8px 0;
}

.loader-item {
  display: flex;
  box-shadow: 0 2px 4px 0px #ccc;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 4px;
  width: 350px;

  &.first {
    margin-top: 30px;
  }
  .loader-item-text {
    margin-left: 8px;
  }
}

.page-not-found__container {
  display: flex;
}

.page-not-found__info{
    flex:1;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.page-not-found__logo {
  width: 90px;
  margin: 50px 0;
}

.page-not-found__title {
  font-size: 36px;
  font-family: "Lato";
  color: #474747;
}

.page-not-found__error-code {
  font-size: 120px;
  font-family: "Lato";
  color: #767676;
  margin: 28px;
  line-height: initial;
}

.page-not-found__error-message {
  font-size: 28px;
  font-family: "Lato";
}

.page-not-found__home-link {
  font-size: 23px;
  color: #1bace4;
  font-family: "Lato";
}

.page-not-found__bg-img-container {
  background-image: url("../../assets/shared/login-bg.png");
  flex: 1;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
}

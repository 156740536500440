.login-img {
  background: url("../../assets/shared/login-bg.png") no-repeat 100%;
  background-size: cover;
  height: 100%;
}

.login.translate.dropdown {
  top: 10px;
}

.login-container {
  justify-content: center;
  display: flex;
  @media (max-width: 767px) {
    padding: 2rem;
  }

  @media (min-width: 1339px) {
    justify-content: flex-end;
    padding: 0 6.125rem 0 0;
  }

  .login-content {
    max-width: 22rem;
    @media (min-width: 320px) and (max-width: 479px) {
      max-width: 100%;
      margin: 30px 0;
    }
    @media (min-width: 480px) and (max-width: 767px) {
      max-width: 100%;
      margin: 24px 0 0 0;
    }

    .login-form {
      margin: 5rem 0 0 0;
      input {
        border: 1px solid #000;
      }

      .login-input-labels {
        color: #474747;
      }
    }
  }

  .login-header {
    margin-top: 2.5rem;
    display: flex;
    justify-content: space-between;
    @media (min-width: 320px) and (max-width: 767px) {
      margin: 0;
    }
  }

  .login-footer .btn.btn.btn-primary {
    padding: 0.7rem 2.6em;
  }

  .login-footer.fr-lang .btn.btn.btn-primary {
    padding: 0.7rem 2.6em;
  }

  .login-footer.pl-lang .btn.btn.btn-primary {
    padding: 0.7rem 2.3em;
  }
  .login-footer .btn.btn.btn-primary.btn-login {
    margin-right: 32px;
    width: 50%;
    padding: 0.7rem 2em;
    &:active {
      background-color: #1bace4;
      border-color: #1bace4;
    }
  }

  .login-footer .login-btn-wrapper {
    margin-top: 24px;
  }
  .login-footer .btn.btn.btn-primary.btn-signup {
    width: 50%;
    padding-left: 0;
    padding-right: 0;
  }

  .login-footer .custom-checkbox {
    padding-left: 24px;
  }

  .login-footer .custom-checkbox .checkbox-label {
    color: #000;
  }

  .login-footer .custom-checkbox .checkmark {
    top: 4px;
    height: 16px;
    width: 16px;
  }

  .login.translate.dropdown {
    .flag-div {
      width: 2rem;
      height: 2rem;
      margin-right: 0;
      //border: 1px solid #ccc;
    }
  }

  .popup-user-inactive {
    font-size: 16px;
    color: #474747;
    .popup-support-email {
      color: #1bace4;
    }
  }
}

.login.translate.dropdown button {
  padding: 0;
}

.login.translate.dropdown button .flag-div {
  width: 2rem;
  height: 2rem;
}

.login.translate.dropdown button .flag-div img {
  width: 100%;
  height: 100%;
  top: 0;
}

.login.translate.dropdown .dropdown-toggle::after {
  display: none;
}

.login.translate.dropdown .dropdown-menu.show {
  right: 0;
  padding: 0;
  left: -6px !important;
}

.login.translate.dropdown .bottom-lang {
  background-color: whitesmoke;
}

.login.translate.dropdown .dropdown-item {
  margin: 0;
  padding: 0.4rem;
}

.login.translate.dropdown .dropdown-item img {
  width: 22px;
  height: 22px;
}

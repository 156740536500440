.enquiry-form__wrapper--center {
  text-align: center;
  padding: 16px;
  @media screen and (min-width: 768px) {
    padding: 100px;
  }
}
.enquiry-form__success-msg {
  color: #1bace4;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  white-space: break-spaces;
  font-family: Ubuntu;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
}

.enquiry-form__btn--success {
  text-decoration: none;
  margin-top: 56px;
  border: 0;
  display: inline-block;
  padding: 10px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  .fa {
    vertical-align: middle;
    margin-left: 5px;
  }

  @media screen and (max-width: 768px) {
    padding: 0.8rem 15px;
  }
}

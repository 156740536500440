.v3-inquiry-form__container {
  padding: 32px 16px;
  overflow-y: auto;
  height: calc(100vh - 200px);
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: #c5c5c5;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #888;
  }

  @media screen and (min-width: 768px) {
    padding: 32px 102px 88px 102px;
  }

  .rs-slider-handle {
    top: -30%;
  }

  .rs-slider-handle:before {
    width: 20px;
    height: 20px;
    background-color: #1bace4;
    border: 2px solid #1bace4;
  }

  .type-enquiry {
    @media screen and (max-width: 768px) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .category-selection-container {
    .request-category-items {
      li {
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
        background-color: white;
        border: 1px solid #e5e5e5;
        transition: all 0.2s ease-in;
        &.active {
          background-color: #c9e8fb;
        }

        @media screen and (max-width: 768px) {
          margin-right: 6px;
        }
      }
    }
  }
  .v3-inquiry-form__category-loading {
    margin-top: 60px;
    text-align: center;
  }

  .file-input-label {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #474747;
  }
  .btn.btn-primary.upload-btn {
    padding: 14px;
    font-family: Lato !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: #004c7f;
    &:hover {
      color: white;
    }
  }
  .upload-files-table .file-name {
    font-family: Lato !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 16px !important;
    line-height: 22px !important;
    color: #474747;
  }
  .upload-files-table {
    margin-top: 16px;
  }
}

.v3-inquiry-form__no-service-description {
  font-size: 18px;
  line-height: 22px;
  color: #1bace4;
}

.v3-inquiry-form__jobsite-input-wrapper {
  position: relative;
}

.v3-inquiry-form__jobsite-label {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #474747;
  position: absolute;
  left: 10px;
  top: 7px;
  z-index: 1;
  transition: transform 150ms ease-out, font-size 150ms ease-out;

  &.focused {
    background-color: #fff;
  }

  &.required::after {
    content: "*";
    color: #ea6457;
    font-weight: bold;
  }
}

.v3-inquiry-form__jobsite-input--focussed .v3-inquiry-form__jobsite-label {
  transform: translateY(-115%);
  font-size: 0.75em;
  background-color: #fff;
}

.v3-inquiry-form__jobsite-input.v3-inquiry-form__jobsite-input--filled {
  border: 1px solid #32a728;
}

.v3-inquiry-form__menu {
  padding: 0;
  list-style-type: none;
  margin: 0;
}

.v3-inquiry-form__menu-item {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  padding: 10px;
  color: #1bace4;
  background-color: #fff;
  border: 1px solid #1bace4;
  border-radius: 5px;
  margin-bottom: 4px;
  &:last-child {
    margin-bottom: 0;
  }
  &:hover,
  &:focus {
    color: #ffffff;
    background: #1bace4;
    cursor: pointer;
  }
  &.error {
    border: none;
    color: #f91608;
    &:hover,
    &:focus {
      color: #f91608;
      background: #fff;
      cursor: auto;
    }
  }
}

.v3-inquiry-form__job-site-input {
  display: block;
  width: 100%;
  color: #575757;
  color: var(--rs-text-primary);
  background-color: #fff;
  background-color: var(--rs-input-bg);
  background-image: none;
  border: 1px solid #e5e5ea;
  border: 1px solid var(--rs-border-primary);
  -webkit-transition: border-color 0.3s ease-in-out;
  transition: border-color 0.3s ease-in-out;
  border-radius: 6px;
  padding: 7px 11px;
  font-size: 14px;
  line-height: 1.42857143;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #474747;
  border: 1px solid #474747;
  &:focus,
  &:hover {
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.1);
    outline: none;
    border: 1px solid #1bace4;
    background-color: #fff;
  }
  &.v3-inquiry-form__job-site-input--filled {
    border: 1px solid #32a728;
  }
}

.v3-inquiry-form__tender-date-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  flex-direction: row;
  align-items: center;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: start;
  }
}

.v3-inquiry-form__tender-wrapper {
  flex: 1;
  padding: 0.5rem;
  border: none;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  .checkmark {
    left: 0 !important;
  }
}

.v3-inquiry-form__date-wrapper {
  flex: 1;
  max-width: 300px;
  position: relative;

  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: 100%;
    margin-top: 22px;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }
}

.v3-inquiry-form__range-slider-wrapper {
  margin-bottom: 15px;
}

.v3-inquiry-form__range-slider-title {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  margin: 0 0 45px 0;
}

.v3-inquiry-form__range-slider {
  height: 14px;
  border-radius: 4px;
  background: #cccccc;

  .rs-slider-progress-bar {
    height: 14px;
    border-radius: 4px;
    background: #c9e8fb;
  }
}

.v3-inquiry-form__date-input {
  background-color: #fff;
  border: 1px solid #474747;
  border-radius: 4px;
  padding: 8px 28px 8px 8px;
  height: 40px;
  color: #474747;
  font: 1rem/1.38 "Lato", sans-serif;
  width: 100%;

  &:focus {
    background-color: #fff;
    outline: none;
    border: 1px solid #1bace4;
  }
  &.v3-inquiry-form__date-input--filled {
    border: 1px solid #32a728;
  }
}

.v3-inquiry-form__no-service {
  margin-top: 52px;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
}

.v3-inquiry-form__no-service-message {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 46px;
  color: #1bace4;
  margin: 0 0 38px 0;
}

.v3-inquiry-form__comment {
  padding: 0 0 22px 0;
  position: relative;
  margin-top: 30px;
}

.v3-inquiry-form__comment-input {
  background-color: #fff;
  border-radius: 4px;
  padding: 8px 28px 8px 8px;
  height: 72px;
  font: 1rem/1.38 "Lato", sans-serif;
  border: 1px solid #474747;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #474747;
  &:hover,
  &:focus {
    border: 1px solid #1bace4;
    outline: none;
  }
  &.v3-inquiry-form__comment-input--filled {
    border: 1px solid #32a728;
  }
}

.v3-inquiry-form__comment-label {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #474747;
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 0;
  transition: transform 150ms ease-out, font-size 150ms ease-out;

  &.focused {
    background-color: #fff;
  }

  &.required::after {
    content: "*";
    color: #ea6457;
    font-weight: bold;
  }
}

.v3-inquiry-form__comment--focussed .v3-inquiry-form__comment-label {
  transform: translateY(-125%);
  font-size: 0.75em;
  background-color: #fff;
}

.no-sites {
  display: flex;
  padding: 24px 0;
  .site-icon-wrapper {
    border-radius: 50% !important;
    border: 1px solid #ccc;
    background-color: #ededed;
    width: 80px;
    height: 80px;
    justify-content: center;
    display: flex;
    align-items: flex-end;
    overflow: hidden;
    margin: 0 16px 0 0;
    .site-icon {
      width: 58px;
    }
  }

  .no-sites-description-wrapper {
    width: calc(100% - 96px);
    .no-sites-title {
      font-size: 16px;
      font-weight: bold;
      color: #474747;
      font-family: "Lato";
      line-height: 22px;
      margin: 0;
    }
    .no-sites-description {
      font: 0.875rem/1.43 "Lato", sans-serif;
      font-weight: bold;
      margin: 0 0 8px 0;
      color: #767676;
      line-height: 20px;
    }
  }
  .btn-create-new-site {
    padding: 5px;
    width: 200px;
    @media (min-width: 768px) {
      padding: 11px 17px;
      width: auto;
    }
    &.disabled {
      border: 2px solid #1bace4;
      background-color: #ccc;
      color: #fff;
    }
  }
}

.reset-password {
  font: bold 1rem/1.38 "Lato", sans-serif;
  color: #1bace4;
}
.reset-password:hover {
  color: #1bace4;
}

.onboarding-link {
  font: bold 1rem/1.38 "Lato", sans-serif;
  color: #1bace4;
  display: block;
  margin-top: 20px;
  text-align: center;
  &:hover {
    color: #1bace4;
  }
}

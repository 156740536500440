.v3-footer__container {
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  box-shadow: 0 -4px 4px 0 rgba(0, 0, 0, 0.1);

  @media screen and (min-width: 768px) {
    padding: 16px 46px 16px 46px;
  }

  .btn.btn-primary {
    padding: 8px;
    min-width: 150px;
    transition: all 0.2s ease-in;
    @media screen and (min-width: 768px) {
      padding: 0.8rem 1.3em;
    }
  }

  .fa {
    vertical-align: middle;
    margin-left: 5px;
  }
}

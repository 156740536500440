.no-suggestions {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-indent: 10px;
  padding: 10px 0;
  color: #1bace4;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 4px;
}

.suggestions-list-loading {
  margin-top: 15px;
  text-align: center;
}

.header-container {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10000;

  .navbar {
    padding: 16px 0 12px 0;
    .container-fluid {
      max-width: 1440px;
      margin: 0 auto;
      padding-right: 20px;
      padding-left: 8px;
      @media (min-width: 1440px) {
        padding-right: 60px;
        padding-left: 36px;
      }
      .header-row {
        align-items: center;
        width: 100%;
        margin: 0;
        flex-wrap: nowrap;
        .fa-align-justify {
          // font-size: 18px;
        }
        .default-user {
          font-size: 18px;
        }
      }
    }
  }

  .flag-div {
    //border: 1px solid #ccc;
    background-color: #f5f5f5;
    img {
      width: 100%;
      height: 100%;
      top: 0;
    }
  }
  .dropdown-menu {
    .dropdown-item {
      img {
        width: 22px;
        height: 22px;
      }
    }
  }
  .translate.dropdown .dropdown-toggle {
    padding-right: 0;
  }
  .translate .dropdown-menu.show {
    right: 0;
  }
  .dropdown-toggle::after {
    display: none;
  }
}
.jobsite-title {
  font-family: Ubuntu;
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: #474747;
}
.jobsite-id {
  font-size: 14px;
  line-height: 20px;
  color: #767676;
  margin-left: 8px;
  font-weight: bold;
}

.navbar {
  padding: 15px 10px;
  background: #fff;
  border: none;
  border-radius: 0;
  margin-bottom: 24px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}

.navbar {
  width: 100%;
}

.nav-btn {
  color: #004c7f;
  background: none;
  border: none;
}

.sticky.list-header {
  top: 84px;
  background: #fff;
  z-index: 1;
}

.sticky + .content {
  padding-top: 102px;
  position: relative;
  z-index: -1;
}
.sticky + .content.listing-content {
  z-index: auto;
}

.signout.dropdown-menu,
.sign-out-btn {
  font: 0.875rem/1.43 "Lato", sans-serif;
  color: #1bace4;
}
.signout.dropdown-menu a:hover,
.sign-out-btn:hover {
  background: #1bace4;
  color: #fff;
}

.signout.dropdown-menu {
  min-width: auto;
}

.btn-info:hover,
.btn-info:not(:disabled):not(.disabled):active,
.btn-info:focus,
.btn-info:not(:disabled):not(.disabled):active:focus {
  background-color: transparent;
  border-color: transparent;
  color: #004c7f;
  box-shadow: none;
}
.btn-info:focus {
  outline: none;
  box-shadow: none;
}

.view-page-tabs .nav-item .link {
  padding: 0 8px 6px 8px;
}

.view-page-tabs .nav-item .link.active {
  color: #1bace4;
  border-bottom: 2px solid #1bace4;
  padding: 0 8px 6px 8px;
}

.view-page-tabs {
  bottom: 5px;
}
.view-page-tabs .nav-item {
  padding: 0 8px;
}
.view-page-tabs .nav-item:first-child {
  padding: 0 8px 0 0;
}
.view-page-tabs .nav-item .link {
  color: #767676;
  text-transform: capitalize;
}
.view-page-tabs .nav-item .link::first-letter {
  text-transform: uppercase;
}

.jobsite-selector {
  @at-root #{&}__title {
    font: bold 1rem/1.38 "Lato", sans-serif;
    color: #004c7f;
    margin: 0 0 14px 0;
  }

  @at-root #{&}__loader {
    text-align: center;
    padding: 25px 0;
  }

  @at-root #{&}__dropdown-wrapper {
    background: #f6f6f6;
    padding: 16px;
    margin-bottom: 1.5rem;
    border: 0;
    display: flex;
    align-items: flex-start;
  }

  @at-root #{&}__img-wrapper {
    padding: 0;
    margin-right: 0.5rem;
    border-radius: 0;
  }

  @at-root #{&}__img {
    margin: 0;
  }

  @at-root #{&}__input-wrapper {
    flex: 1 1;
    position: relative;
  }

  @at-root #{&}__input {
    border: 1px solid #000;
    color: #474747;
    font: 0.875rem/1.43 "Lato", sans-serif !important;
    text-transform: uppercase;
    padding: 0.5rem;
    margin: 0;
    width: 100%;
    height: 40px;
    border-radius: 4px;
  }

  @at-root #{&}__input-container {
    padding: 0;
  }

  @at-root #{&}__jobsite-info-wrapper {
    margin-left: 1rem;
    flex: 1 1;
  }

  @at-root #{&}__jobsite-name {
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #474747;
    margin: 0;
    text-transform: uppercase;
  }

  @at-root #{&}__jobsite-id {
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    color: #767676;
    margin: 0;
  }
}

.site-btn-max {
  max-width: 1440px;
  margin: 0 auto;
  padding-left: 8px;
  @media (min-width: 1440px) {
    padding-left: 36px;
  }
  .load-more-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
}

.sites-scroll-view {
  overflow: hidden !important;
}

.data-table .title {
  font: bold 0.875rem/1.43 "Lato", sans-serif;
  color: #004c7f;
  padding: 8px;
  display: inline-block;
}

.data-table .row.list-row {
  border-bottom: 1px solid #ededed;
}

.data-table .row.list-row:hover {
  background-color: #c9e8fb;
}

.data-table .row.gray-bg:hover {
  background-color: #f6f6f6;
}

.data-table .column-text,
.data-table .column-text h4 {
  font: bold 1rem/1.38 "Lato", sans-serif;
  color: #474747;
  padding: 8px;
  vertical-align: middle;
}
.data-table .title:nth-child(2),
.data-table .column-text:nth-child(2) {
  border-left: 0;
}
.data-table .column-text:last-child {
  border-right: 0;
}

.documents-title {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.375rem;
  color: #004c7f;
  margin: 0;
  border-bottom: 1px solid #cccccc;
  padding: 0 0 0.625rem 0;
}

.no-documents {
}

.documents-list {
  // max-height: 150px;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding-left: 0.5rem;
  margin:0;

  &::-webkit-scrollbar {
    display: none;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  .document-title {
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #474747;
  }

  li {
    list-style-type: none;
    padding: 5px 0;
    display: flex;
    align-items: center;
    overflow: hidden;
  }
}


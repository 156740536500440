.v3-contact-form__container {
  .enquiry-form__wrapper {
    margin: 0 48px;
    @media screen and (max-width: 768px) {
      margin: 0 16px;
    }
  }
}
.enquiry-form__user-title,
.enquiry-form__company-title {
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #004c7f;
}

.enquiry-form__wrapper.onboarding-v3__enquiry-form-container {
  background-color: #fff;
  padding: 20px 0 15px 0;
  margin: 0 auto;
  height: auto;

  .enquiry-form__title {
    text-align: center;
    margin: 0 0 20px 0;
    font: bold 1.25rem/1.4 "Ubuntu", sans-serif;
    color: #000;
    font-size: 18px;
  }

  .enquiry-form__categories-title,
  .enquiry-form__product-title {
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #004c7f;
  }

  .enquiry-form__categories-wrapper {
    label {
      color: #004c7f;
    }
  }

  .enquiry-form__quantity-unit-wrapper {
    margin-top: 10px;
    @media screen and (max-width: 575px) {
      > div:first-child {
        margin-bottom: 18px;
      }
    }
    .v3-unit-input {
      background-color: #fff;
      border: 1px solid #474747;
      border-radius: 4px;
      padding: 8px 28px 8px 8px;
      height: 40px;
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: #474747;
      &:hover,
      &:focus {
        border: 1px solid #1bace4;
        outline: none;
      }
      &.v3-unit-input--filled {
        border: 1px solid #32a728;
      }
    }
  }

  .enquiry-form__btn-step--prev {
    background-color: white !important;
    color: #1bace4 !important;
  }

  .v3-inquiry-form__usage-input {
    background-color: #fff;
    border: 1px solid #474747;
    &:hover,
    &:focus {
      border: 1px solid #1bace4;
      outline: none;
    }
    &.v3-inquiry-form__usage-input--filled {
      border: 1px solid #32a728;
    }
  }

  .rs-radio-group-inline {
    align-items: center;
    text-transform: capitalize;
  }
  .rs-radio-inline {
    margin: 0;
  }
  .rs-radio label {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #474747;
  }
  .rs-radio-inner:after {
    width: 10px;
    height: 10px;
    margin-left: 4px;
    margin-top: 4px;
  }
  .rs-radio.rs-radio-checked .rs-radio-inner:before {
    border: 2px solid #1bace4;
    background-color: transparent;
    width: 18px;
    height: 18px;
  }
  .rs-radio-checked .rs-radio-inner:after {
    background-color: #1bace4;
  }
  .rs-tooltip {
    background-color: transparent;
    color: #dc3545;
    top: 28px;
    left: 8px;
  }
}

.create-site-btn-wrapper {
  padding: 16px 0 16px 16px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  // background-image: url("../../../assets/shared/add-border-icon.svg");
  // background-repeat: no-repeat;
  background-color: transparent;
  border:1px dashed #c2c2c2;
  cursor: pointer;

  &:hover {
    background-color: #c9e8fb;
  }
  .create-site-text {
    margin: 0 0 0 1rem;
    font-family: "Lato";
    font-size: 16px;
    color: #474747;
    font-weight: bold;
  }
}

$quote-sent-status-bg-color: #4a90e2;
$quote-in-progress-bg-color: #ff9800;
$quote-won-bgn-color: #32a728;
$border-color: #ededed;

.model-pad {
  @media (min-width: 320px) and (max-width: 767px) {
    padding: 0 40px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    padding: 0 10px;
  }
}

.edit-enquiry-btn {
  top: 20px;
  right: 70px;
  display: inline;

  @media (min-width: 320px) and (max-width: 767px) {
    display: none;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    display: none;
  }
}

.request-view-popup {
  .loader-container {
    display: flex;
    justify-content: center;
    padding: 40px 0;
  }
  .my-enquiries-title-wrapper .title {
    border: none;
    padding: 0;
  }
  .my-enquiries-title-wrapper {
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .quote-status {
    background: white;
    border-radius: 4px;
    color: white;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    padding: 3px 10px;
    text-align: center;
    height: 23px;
    min-width: 105px;
    margin: 5px 0;
    @media (min-width: 768px) {
      margin: 0 0 0 5px;
    }

    &.in-progress {
      background-color: $quote-in-progress-bg-color;
    }

    &.quote-sent {
      background-color: $quote-sent-status-bg-color;
    }

    &.won {
      background-color: $quote-won-bgn-color;
    }
    &.canceled {
      background-color: #ccc;
    }
  }
  .btn.btn-primary:disabled {
    cursor: not-allowed;
    background-color: #f6f6f6;
    border: 2px solid #ccc;
    color: #767676;
  }
  .request-loader-wrapper {
    display: flex;
    .request-loader-service {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
    }
  }

  .jobsite-id {
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    color: #767676;
    margin: 5px 0 0 0;
  }
  .quotes-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: scroll;
    height: 140px;
    // scrollbar-width: none;
    // -ms-overflow-style: none;

    // &::-webkit-scrollbar {
    //   display: none;
    // }
    .quotes-list-item {
      background-color: white;
      border-radius: 4px;
      padding: 18px;
      margin-bottom: 10px;
      display: flex;
      .quote-icon {
        width: 40px;
        margin-right: 16px;
      }
      .quote-info {
        &:last-child {
          margin-left: 10px;
        }
      }
      .quotes-service-name {
        font-family: Lato;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 0px;
        text-align: left;
        color: #767676;
        margin: 0;
      }
      .quotes-price {
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0px;
        text-align: left;
        color: #474747;
        margin: 0;
      }
    }
  }
  .transport-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    height: auto;

    .transport-main {
      white-space: nowrap;
      width: auto;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;
      line-height: 40px;
      pointer-events: none;
    }

    .transport-main .show-dot:after {
      content: "...";
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
      width: auto;
      height: auto;
      z-index: 1;
      pointer-events: initial;
    }

    .transport-main .show-dot:hover:after {
      cursor: pointer;
    }

    .transport-hover {
      width: 250px;
      visibility: hidden;
      background-color: #fff;
      padding: 20px;
      box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.3);
      opacity: 0;
      transition: opacity 0.5s ease;
      position: absolute;
      left: 5%;
      bottom: 40px;
      z-index: 1;
    }

    .transport-main:hover + .transport-hover {
      visibility: visible;
      transition: opacity 0.5s ease;
      opacity: 1;
    }
  }
  .request-container {
    max-height: 500px;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .request-document-list {
    // max-height: 150px;
    overflow: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    ul {
      margin: 0;
      padding: 0;
    }

    .document-title {
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: left;
      color: #474747;
    }

    li {
      list-style-type: none;
      padding: 5px 0;
      display: flex;
      align-items: center;
      overflow: hidden;
    }
  }
}

.request-view-popup {
  overflow-y: scroll;
  padding: 80px 0 40px 0;
  z-index: 110000;

  .media-img {
    margin-right: 16px;
  }
}

.request-view-popup .modal-body {
  padding: 0;
}
.request-view-popup .modal-footer {
  display: none;
}
.request-view-popup .modal-header {
  padding: 24px;
  position: relative;
}
.request-view-popup .media-body h5 {
  color: #474747 !important;
}
.request-view-popup .media-body .id {
  font: bold 0.875rem/1.43 "Lato", sans-serif;
}

.request-view-popup .media-body .service-info {
  font: bold 0.75rem/1.33 "Lato", sans-serif;
}

@media (min-width: 576px) {
  .request-view-popup .modal-dialog {
    max-width: 1120px;
  }
}

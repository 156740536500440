.my-sites {
  margin: 0 0;

  @media (min-width: 768px) {
    margin-left: 16px;
    margin-right: 16px;
  }

  .site-img-wrapper {
    border-radius: 50% !important;
    border: 1px solid #ccc;
    background-color: #ededed;
    width: 80px;
    height: 80px;
    justify-content: center;
    display: flex;
    align-items: flex-end;
    overflow: hidden;
    margin: 0 16px 0 0;
  }

  .site-img {
    width: 40px;
    height: auto;
    margin: 0 16px 0 0;
  }

  .dashboard-sites-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      list-style-type: none;
    }

    .site-item {
      border-bottom: 1px solid #ededed;
      padding: 16px 0 16px 16px;
      display: flex;
      align-items: flex-start;
      background-color: white;
      cursor: pointer;

      .site-info {
        .site-name {
          font-size: 16px;
          font-family: "Lato", sans-serif;
          color: #474747;
          font-weight: bold;
          text-transform: uppercase;
          line-height: 22px;
          margin: 0;
        }

        .site-details {
          font: 0.75rem/1.33 "Lato", sans-serif;
          color: #767676;
          padding: 0;
          margin: 0;
          line-height: 16px;
          font-weight: bold;
        }
      }
    }
  }
}

.custom-checkbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);

  &:focus + label::before {
    outline: 1px solid #1bace4;
    border: 2px solid #1bace4;
  }

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #1bace4;
    margin: 0;
  }

  // Box.
  & + label:before {
    content: "";
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 18px;
    height: 18px;
    background: white;
    border: 2px solid #ccc;
    border-radius: 3px;
  }

  // Box hover
  &:hover + label:before {
    background: #1bace4;
    border: 2px solid #1bace4;
  }

  // Box checked
  &:checked + label:before {
    background: #1bace4;
    border-color: #1bace4;
  }

  // Disabled state label.
  &:disabled + label {
    color: #ccc;
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    cursor: not-allowed;
    border-color: #ccc;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: "";
    position: absolute;
    left: 4px;
    top: 9px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
      4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
  }

  &[disabled]:checked + label:before {
    background: #ccc;
  }
  &[disabled]:hover + label:before {
    background: transparent;
  }
}

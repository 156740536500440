.floating-datepicker__container {
  .datepicker-label {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #474747;
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 1;
    transition: transform 150ms ease-out, font-size 150ms ease-out;

    &.focused {
      background-color: #fff;
    }
  }

  .calendar-icon {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }

  .custom-datepicker-calendar {
    background-color: white;
    font-family: "Lato";
    border: 1px solid #fff;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 12px 0;
    .react-datepicker__navigation {
      top: 22px;
    }
    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
      background-color: white;
    }
    .react-datepicker__navigation--previous {
      background-image: url("../../../assets/shared/ic-arrow-left.svg");
      background-size: 30px;
      background-position: center;
      width: 30px;
      height: 30px;
      border: none;
    }
    .react-datepicker__navigation--next {
      background-image: url("../../../assets/shared/ic-arrow-right.svg");
      background-size: 30px;
      background-position: center;
      width: 30px;
      height: 30px;
      border: none;
    }
    .react-datepicker__header {
      background-color: white;
      border-bottom: none;
      border-top-left-radius: 0.3rem;
      padding-top: 8px;
      position: relative;
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: center;
      color: #474747;
      padding: 0;
    }
    .react-datepicker__current-month {
      padding: 12px 6px;
      display: inline-block;
      text-transform: capitalize;
    }
    .react-datepicker__header__dropdown--select {
      display: inline-block;
      .react-datepicker__year-select {
        width: 64px;
        border: none;
        outline: none;
        font-size: 1rem;
        font-family: Lato;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0px;
        text-align: left;
        color: #474747;
      }
    }
    .react-datepicker__day-names {
      border-top: 1px solid #ededed;
    }
    .day-class {
      border: none;
      background: #fff;
      font-weight: normal;
      color: #474747;
      font-family: Lato;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: center;
      padding: 5px 0;
      border-radius: 4px;
    }
    .react-datepicker__day--selected {
      border: 1px solid #1bace4;
      background: #1bace4;
      color: #fff;
    }
    .react-datepicker__day--today {
      border: 1px solid #767676;
      background: #767676;
      color: #fff;
    }
    .react-datepicker__day-name {
      padding: 0.7em 0.3em 0 0.3em;
      border: 0;
      font-family: Lato;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: center;
      color: #767676;
      text-transform: capitalize;
    }
    .react-datepicker__day--disabled {
      color: #ccc;
    }
    .react-datepicker__month {
      margin: 1rem;
    }
    .react-datepicker__day--outside-month {
      opacity: 1 !important;
    }
    .react-datepicker__year-select {
      option {
        font-size: 16px;
      }
    }
    .react-datepicker__header__dropdown {
      display: inline-block;
    }
    .react-datepicker__year-read-view {
      visibility: visible !important;
      border-bottom: 1px solid rgb(27, 172, 228);
    }
    .react-datepicker__year-dropdown {
      background-color: white;
      border: 1px solid #ccc;
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1);
      width: 73px;
      left: 44%;
      top: 39px;
    }
    .react-datepicker__year-option--selected {
      display: none;
    }
    .react-datepicker__year-option {
      padding: 10px;
      font-size: 14px;
      font-family: Lato;
      color: #474747;
      &:hover {
        background-color: #1bace4;
        color: white;
      }
      .react-datepicker__year-option--selected_year {
        background-color: #1bace4;
        color: white;
      }
    }
    .react-datepicker__year-read-view--selected-year {
      font-size: 16px;
      font-family: Lato;
      color: #474747;
    }
  }
}

.floating-datepicker__container--focussed {
  position: relative;
  .datepicker-label {
    transform: translateY(-125%);
    font-size: 0.75em;
    background-color: #fff;
  }
}

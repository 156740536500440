.add-site {
  padding-top: 8px;
  @media (min-width: 320px) and (max-width: 767px) {
    padding-top: 15px;
  }
}

.site-btn-max {
  max-width: 1440px;
  margin: 0 auto;
}

.list-site-btn {
  margin-left: 4rem;
  @media (min-width: 320px) and (max-width: 767px) {
    margin-left: 4px;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-left: 2.4rem;
  }
  @media (min-width: 1024px) and (max-width: 1200px) {
    margin-left: 2.4rem;
  }
  @media (min-width: 1920px) {
    margin-left: 5rem;
  }
}

.title-site {
  margin-left: 3.5rem;
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-left: 2rem;
  }
  @media (min-width: 1024px) and (max-width: 1200px) {
    margin-left: 1.8rem;
  }
  @media (min-width: 1920px) {
    margin-left: 4.5rem;
  }
}

.list-header-items {
  margin: 12px 0 0 0;
}
.list-header-items li {
  list-style-type: none;
  display: inline-block;
  color: #474747;
}

.category-selection-container {
  .request-category-items li {
    list-style-type: none;
    padding: 16px 8px 8px 8px;
    background: #f6f6f6;
    border: 1px solid #ccc;
    margin: 0 16px 16px 0;
    min-width: 160px;
    border-radius: 4px;
    &:last-child {
      margin: 0 0 16px 0;
    }
    &:nth-of-type(3n) {
      margin-right: 0;
    }
    &.active {
      background-color: #c9e8fb;
    }
    @media (min-width: 320px) and (max-width: 767px) {
      margin: 0 0 16px 0;
    }
  }
}

.request-category-items {
  margin: 0;
  padding: 0;
}
.request-category-items.disabled {
  opacity: 0.5;
}
.request-category-items li {
  list-style-type: none;
  padding: 16px 8px 8px 8px;
  background: #f6f6f6;
  border: 1px solid #ccc;
  margin: 0 0 16px 0;
  min-width: 160px;
  border-radius: 4px;
}
.request-category-items li:last-child {
  margin: 0 0 16px 16px;
}
.request-category-items li:first-child {
  margin: 0 16px 16px 0;
}

.request-category-items li:hover,
.request-category-items li.active {
  background-color: #c9e8fb;
  border: 1px solid #1bace4;
}
.request-category-items li.active {
  border: 2px solid #1bace4;
}
.request-category-items li.disabled {
  pointer-events: none;
  cursor: not-allowed;
}
.request-category-items li span {
  margin: 6px 0 0 0;
}

.request-category-items li:hover .circle-thin,
.request-category-items li.active .circle-thin {
  background-color: #1bace4;
}

@media (min-width: 320px) and (max-width: 767px) {
  .request-category-items li:last-child {
    margin: 0 0 16px 0;
  }
  .request-category-items li:first-child {
    margin: 0 0 16px 0;
  }
}

.circle-thin {
  width: 8px;
  height: 8px;
  background-color: #474747;
  border: 1px solid #ccc;
  border-radius: 50%;
  top: 5px;
  right: 5px;
}

.custom-radio-options {
  @at-root #{&}__title {
    color: #767676;
    font: 1rem/1.38 "Lato", sans-serif;
    &.required::after {
      content: "*";
      color: #ea6457;
      margin-left: 4px;
    }
  }

  @at-root #{&}__options-list {
    margin: 0;
    padding: 0;
    &.disabled {
      opacity: 0.5;
    }
  }

  @at-root #{&}__options-item {
    list-style-type: none;
    padding: 16px 8px 8px 8px;
    background: #f6f6f6;
    border: 1px solid #ccc;
    margin: 0 0 16px 0;
    min-width: 160px;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    display: inline-block;
    &.active {
      border: 1px solid #1bace4;
      background-color: #c9e8fb;
      .custom-radio-options__option-dot {
        background-color: #1bace4;
      }
    }
    &:nth-child(odd) {
      margin: 0 16px 16px 0;
    }

    &:hover {
      border: 1px solid #1bace4;
      background-color: #c9e8fb;
      .custom-radio-options__option-dot {
        background-color: #1bace4;
      }
    }
  }

  @at-root #{&}__option-icon {
    margin: 0 auto 6px auto;
    display: block;
    width: 64px;
    height: 64px;
  }

  @at-root #{&}__option-name {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #474747;
    display: block;
  }

  @at-root #{&}__option-dot {
    position: absolute;
    margin: 0;
    color: #474747;
    width: 8px;
    height: 8px;
    background-color: #474747;
    border: 1px solid #ccc;
    border-radius: 50%;
    top: 5px;
    right: 5px;
  }
}

@import url("https://fonts.googleapis.com/css?family=Lato|Ubuntu&display=swap");

/****BOOTSTRAP TABLE*****/

.data-table .row.list-row:hover,
.dashboard-list:hover,
.card.card-hover:hover,
.request-view .add-enquiry-btn:hover {
  background-color: #c9e8fb;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

a[data-toggle="collapse"] {
  position: relative;
}
.dropdown-toggle {
  background-color: transparent;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
/************DASHBOARD**************/
.header-row {
  width: 100%;
  margin: 0 auto;
}
.list-header.sticky .data-table.header-row {
  width: 100%;
}

.card-header h5,
.view-page-col .media-body h5,
.request-view-popup .media-body h5,
.request-view-content h4 {
  font: bold 1rem/1.38 "Lato", sans-serif;
  color: #004c7f;
}

.view-page-col .btn.btn-primary {
  padding: 0.7rem 1.1rem;
}

.data-list-item {
  padding: 16px;
}
.data-list-item {
  border-bottom: 1px solid #ededed;
}

.data-list-item .media-img img {
  position: static;
  width: 40px;
  height: 40px;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

/*********LOGIN FORM*************/

.dropdown-item:hover,
.dropdown-item:focus {
  background-color: #eee;
}

.login-footer .custom-checkbox .checkmark:after,
.tender-checkbox.custom-checkbox .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
}

.link {
  font: bold 1rem/1.38 "Lato", sans-serif;
  color: #1bace4;
  cursor: pointer;
}

.link:hover {
  color: #1bace4;
}
.flag-div {
  height: 32px;
  width: 32px;
}
.flag-div img {
  position: relative;
  top: 2px;
}

/*************SIGNUP PAGE*****************/

.registration-content {
  position: relative;
  left: 10%;
  z-index: 1;
}
.registration-content .translate {
  top: 0;
  right: 70px;
}
.registration-content .media img {
  margin: 0;
}
.registration-header {
  margin: 40px 0 0 0;
}

.registration-form {
  background-color: #fff;
  padding: 40px 80px;
}
.media img {
  margin: 0 32px 0 0;
}
.media-body h5 {
  font: bold 1.5rem/1.33 "Ubuntu", sans-serif;
  color: #474747;
}
.media-body p {
  font: 1rem/1.38 "Lato", sans-serif;
  color: #767676;
}
.registration-form .title {
  font: bold 1.25rem/1.4 "Ubuntu", sans-serif;
  color: #000;
}

.registration-footer {
  box-shadow: 0 -4px 4px 0 rgba(0, 0, 0, 0.1);
  position: sticky;
  bottom: 0;
}
.signup-bg {
  background-color: #004c7f;
}

.registration-footer {
  height: 80px;
  padding: 1rem 2rem;
  background-color: #fff;
}

.registration-footer .btn.btn-primary {
  padding: 11px 40px;
}
.registration-statusPopup .modal-body {
  font: 0.875rem/1.43 "Lato", sans-serif;
}
.eye-icon {
  bottom: 32px;
  right: 10px;
}
.check-icon {
  right: -20px;
  top: 38px;
}

.upload-field .fa.add-icon {
  font-size: 28px;
  padding: 0 0 0 16px;
}
.file-upload-table td {
  padding: 5px;
}
.btn.btn-primary.upload-btn {
  padding: 18px;
  color: #004c7f;
  border: 1px solid #004c7f;
  border-radius: 4px;
  background-color: transparent;
}
.btn.btn-primary.upload-btn:hover {
  color: #fff;
  border: 1px solid #fff;
  background-color: #004c7f;
}
.fa.upload-icon {
  font-size: 18px;
}
.upload-field .fa.delete-icon,
.fa.fa-angle-down,
.fa.fa-angle-up {
  font-size: 20px;
}

input:-internal-autofill-selected {
  color: red !important;
}
input {
  filter: none;
}
input[disabled] {
  cursor: not-allowed;
}

input:not(output):-moz-ui-invalid,select:not(output):-moz-ui-invalid  /*to remove required fields border in firefox */ {
  box-shadow: none;
}
.icon-col {
  border: 1px solid #1bace4;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  height: 100%;
}
.icon-col:hover {
  background: #1bace4;
}
.icon-col .link {
  font: bold 14px/24px "Lato", sans-serif;
}
.icon-col:hover .link,
.icon-col:hover i {
  color: #fff;
}
#forgotPasswordPopup .ok-btn {
  padding: 0.3rem 2.4rem;
}

/*****CREATION PAGE *******/


.close-time-list .error-text {
  white-space: initial !important;
}
.job-site-form {
  padding: 40px 96px;
}
.tender-checkbox {
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 2px 0 22px 0;
}

/*****VIEW PAGE*****/
.label {
  font: bold 0.75rem/1.33 "Lato", sans-serif !important;
  color: #767676;
  margin: 0 0 4px 0;
}

.data-table.header-row {
  background-color: #c9e8fb;
}
/***********REQUEST CREATION ****************/

.related-job-info {
  background-color: #f6f6f6;
}

.request-view-content h4 {
  border-bottom: 1px solid #ccc;
  padding-bottom: 8px;
}
.request-detail-list label {
  font: bold 0.75rem/1.33 "Lato", sans-serif;
  color: #767676;
  display: block;
  margin: 0 0 4px 0;
}
.request-detail-list span {
  font: 1rem/1.38 "Lato", sans-serif;
  color: #474747;
  margin: 0 0 16px 0;
  display: block;
}

.request-document-list i {
  font-size: 20px;
}
.request-document-list li {
  list-style-type: none;
  padding: 5px 0;
}
.request-document-list .link:hover {
  color: #004c7f;
}
.edit-enquiry {
  display: none !important;
}

/**************************************************
****************MEDIA QUERIES**********************
***************************************************/

/* ---- Responsive Small Mobile CSS ---- */
@media (min-width: 320px) and (max-width: 479px) {
  .mobile-img {
    height: 250px;
  }
  .login-form {
    margin: 0;
  }
  .login-content {
    max-width: 100%;
    margin: 30px 0;
  }
  .login-header {
    margin: 0;
  }
  .login-footer .btn.btn.btn-primary {
    margin: 30px 0 10px 0;
  }
  .registration-header {
    margin: 0;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.1),
      0 1px 2px 0 rgba(0, 0, 0, 0.2);
    background-color: #fff;
    padding: 16px 16px 4px 16px;
    position: sticky;
    z-index: 1;
    top: 0;
  }
  .registration-form {
    padding: 32px;
  }
  .media img {
    margin: 0 16px 0 0;
  }
  .media-body h5 {
    font-size: 18px;
  }
  .registration-footer {
    padding: 1rem;
  }
  .registration-content .translate {
    top: 0;
    right: 10px;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .registration-content {
    left: 0 !important;
  }

  /****SITELISTING*****/
  #sidebar.active ul li a .hide-text {
    display: inline;
  }
  .my-account-switch {
    position: absolute;
    top: 0;
    width: 100%;
  }

  .show-desk-menus {
    display: inline;
  }

  #sidebar.active ul li i {
    display: inline;
    font-size: 23px;
  }
  #sidebar.active {
    margin-left: 0;
    width: 100%;
  }

  .edit-enquiry {
    color: #1bace4;
    position: absolute !important;
    margin: 12px -15px 0 0;
    top: 20px;
    right: 70px;
    display: inline !important;
  }
  .modal-parent {
    position: absolute;
  }
  .model-pad {
    padding: 0 40px;
  }
  #sidebar.active ul li a,
  #sidebar.active .sidebar-header {
    text-align: left;
  }
  .mobile-menu {
    display: revert;
    padding: 15px;
    border-bottom: 1px solid rgba(246, 246, 246, 0.5);
  }
  #sidebar ul li i {
    padding: none;
  }

  .signup-bg {
    background-color: #004c7f;
    padding: 25px 0 10px 15px;
  }

  .registration-footer .btn.btn-primary {
    padding: 11px 11px;
  }
  .media {
    display: flex;
    align-items: flex-start;
  }
  .job-site-form {
    padding: 40px 40px;
  }

  .request-category-items li:first-child {
    margin: 0 0 16px 0;
  }
  .type-enquiry {
    display: grid;
  }
  .tab-content {
    min-height: 100vh;
    height: 100%;
    background-color: #f6f6f6;
    margin: 0 -19px;
  }
  .data-table .title {
    font: bold 0.75rem/1.43 "Lato", sans-serif;
    padding: 0 8px;
    color: #767676;
  }
  .data-table .column-text,
  .data-table .column-text h4 {
    padding: 0 8px;
  }
  .data-table .row.list-row {
    margin: 16px 0 0 0;
    padding: 16px 0;
    background-color: #fff;
  }
  .data-table .row.list-row:hover,
  .dashboard-list:hover {
    background-color: #fff;
  }
  .column-text .create-enquiry-btn {
    margin: 16px 0 0 0;
  }
  .media-body {
    flex: 1 1;
  }
}

/* ---- Responsive Large Mobile CSS ---- */
@media (min-width: 480px) and (max-width: 767px) {
  .mobile-img {
    height: 250px;
  }
  .login-form {
    margin: 0;
  }
  .login-content {
    max-width: 100%;
    margin: 24px 0 0 0;
  }
  .login-header {
    margin: 0;
  }

  .registration-header {
    margin: 0;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.1),
      0 1px 2px 0 rgba(0, 0, 0, 0.2);
    background-color: #fff;
    padding: 16px;
    position: sticky;
    z-index: 1;
    top: 0;
  }
  .registration-form {
    padding: 32px;
  }
  .media img {
    margin: 0 16px 0 0;
  }
  .media-body h5 {
    font-size: 1rem;
  }

  .registration-footer {
    padding: 1rem;
  }
  .registration-content .translate {
    top: 0;
    right: 5px;
    padding-top: 25px;
  }
}

/* ---- Responsive Tablet CSS ---- */
@media (min-width: 768px) and (max-width: 1023px) {
  .fontLato14 {
    font: 0.67rem/1.43 "Lato", sans-serif !important;
  }
  .model-pad {
    padding: 0 40px;
  }
  .registration-footer .btn.btn-primary {
    padding: 10px 28px;
  }
  .media img {
    margin: 0 12px 0 0;
  }
  .registration-form {
    padding: 40px;
  }
}

/* ---- Responsive Desktop CSS ---- */
@media (min-width: 1024px) and (max-width: 1200px) {
  .media img {
    margin: 0 12px 0 0;
  }
  .registration-form {
    padding: 40px;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
  }
  #sidebar {
    width: 270px;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 999;
    background: #004c7f;
    color: #fff;
    transition: all 0.3s;
  }
  .col-md-5 {
    flex: none;
    max-width: none;
  }
  .col-md-7 {
    flex: none;
    max-width: none;
  }
}

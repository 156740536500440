.list-site-en {
  margin-left: 2rem;
  .dropdown-btn-placeholder {
    width: 24px;
    height: 24px;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    margin-left: 0rem;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-left: 5px;
  }
  @media (min-width: 1024px) and (max-width: 1200px) {
    margin-left: 0.3rem;
  }
  @media (min-width: 1920px) {
    margin-left: 3rem;
  }
}

.expand-btn {
  left: -10px;
}

.sites-scroll-view .list-row {
  &:hover {
    cursor: pointer;
  }
}

.site-hide {
  display: inline;
  @media (min-width: 320px) and (max-width: 767px) {
    display: none;
  }
}

.edit-enquiry-btn {
  top: 20px;
  right: 70px;
  display: inline;
  @media (min-width: 320px) and (max-width: 767px) {
    display: none;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    display: none;
  }
}

.edit-site {
  display: none !important;
  @media (min-width: 320px) and (max-width: 767px) {
    color: #1bace4;
    position: unset !important;
    top: 20px;
    right: 70px;
    display: inline !important;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    color: #1bace4;
    position: unset !important;
    top: 20px;
    right: 70px;
    display: inline !important;
  }
}
.jobsite-info {
  display: flex;
  flex: 1 1;
  justify-content: space-between;

  .jobsite-name-wrapper {
    display: flex;
    align-items: flex-start;
    width: 75%;
    justify-content: flex-start;
    .jobsite-name {
      // max-width: 170px;
      // overflow: hidden;
      // text-overflow: ellipsis;
      // white-space: nowrap;
      // text-decoration: none;
      cursor: pointer;
      @media (max-width: 767px) {
        // max-width: 200px;
      }
    }
    .jobsite-id {
      margin-left: 10px;
    }
  }

  .jobsite-btn-wrapper {
  }
}

.enquiry-form {
  padding: 40px 90px;

  @media (min-width: 320px) and (max-width: 767px) {
    padding: 40px 30px;
  }

  @at-root #{&}__categories-title {
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #004c7f;
  }

  @at-root #{&}__categories-loader {
    text-align: center;
    padding: 25px 0;
  }
}

$base-font: Lato;
$text-color-primary: #474747;
$text-color-secondary: #767676;
$text-color-tertiary: #1bace4;

$quote-sent-status-bg-color: #4a90e2;
$quote-in-progress-bg-color: #ff9800;
$quote-won-bgn-color: #32a728;
$border-color: #ededed;

.enquiry-row-item {
  cursor: pointer;
  margin-bottom: 1rem;
  padding: 1rem;
  box-shadow: 0 2px 4px 0px #ccc;

  .enquiry-item-details {
    padding: 0;
    margin-bottom: 0;
    display: flex;
    align-items: flex-start;

    .enquiry-item-img-wrapper {
      margin-right: 8px;
      .enquiry-item-img {
        width: 40px;
      }
    }
    .enquiry-item-name-wrapper {
      flex:1 1;
      .enquiry-item-header__name-wrapper{
        display: flex;
        justify-content: space-between;
      }
      .enquiry-item-header__name-id-wrapper{
        display: inline-block;
      }
      .enquiry-item-name {
        font-size: 16px;
        font-weight: 700;
        font-family: "Lato";
        line-height: 22px;
        color: #474747;
        margin: 0;
        display: inline-block;
      }
      .enquiry-item-id {
        font-size: 14px;
        line-height: 20px;
        color: #767676;
        margin-left: 8px;
      }
      .enquiry-status {
        background: white;
        border-radius: 4px;
        color: white;
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
        padding: 3px 10px;
        text-align: center;
        height: 23px;
        min-width: 105px;
        margin: 5px 0;
        @media (min-width: 768px) {
          margin: 0;
        }
    
        &.in-progress {
          background-color: $quote-in-progress-bg-color;
        }
    
        &.quote-sent {
          background-color: $quote-sent-status-bg-color;
        }
    
        &.won {
          background-color: $quote-won-bgn-color;
        }
        &.canceled {
          background-color: #ccc;
        }
      }
    }
    .quantity-wrapper {
      margin: 0;
      font-family: Lato;
      font-size: 12px;
      font-style: normal;
      font-weight: normal;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;
      color: #767676;
      margin-top: 8px;

      .quantity-val {
        font-weight: 700;
        color: #474747;
      }
    }
  }
}

.request-details {
  @at-root #{&}__section-title {
    font: bold 1rem/1.38 "Lato", sans-serif;
    color: #004c7f;
    margin: 0 0 14px 0;
  }

  @at-root #{&}__transport-container {
    padding: 0 0 22px 0;
    .rw-multiselect {
      .rw-input-reset {
        width: 100%;
      }
    }
  }

  @at-root #{&}__product-input {
    position: relative;
    .add-icon {
      left: -30px;
      width: 20px;
      cursor: pointer;
      right: -12px;
      top: 34px;
      color: #004c7f;
      position: absolute;
      font-size: 22px;
    }
    .delete-icon {
      left: -56px;
      width: 20px;
      font-size: 22px;
      cursor: pointer;
      right: -36px;
      top: 34px;
      position: absolute;
      color: #dc3545;
    }
  }

  @at-root #{&}__input-wrapper {
    padding: 0 0 22px 0;
    position: relative;
    .calendar-icon {
      right: 12px;
    }

    .react-datepicker-wrapper {
      width: 100%;
    }
  }

  @at-root #{&}__section-input {
    background-color: #f6f6f6;
    border: 1px solid #000;
    border-radius: 4px;
    padding: 8px;
    height: 40px;
    color: #474747;
    font: 1rem/1.38 "Lato", sans-serif;
    width: 100%;
    filter: none;
    margin: 0;
    outline: none;
    &[name="promocode"],
    &[name="comment"] {
      padding: 8px;
    }
  }

  @at-root #{&}__section-input-label {
    margin-bottom: 3px;
    font: 1rem/1.38 "Lato", sans-serif;
    color: #767676;
    display: inline-block;

    &.required:after {
      content: "*";
      color: #ea6457;
    }
  }
}

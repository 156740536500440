.my-enquiries-title-wrapper {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  .title {
    font-size: 16px;
    font-family: "Lato";
    font-weight: bold;
    color: #004c7f;
    line-height: 22px;
  }
  .view-all {
    border: 2px solid #1bace4;
    border-radius: 4px;
    color: #1bace4;
    font-family: "Lato";
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    padding: 4px 6px;
    margin-bottom: 5px;
  }
}

.transport-form {
  padding: 40px 90px;
  @media (min-width: 320px) and (max-width: 767px) {
    padding: 40px 30px;
  }

  .calendar-icon {
    position: absolute;
    right: 12px;
    top: 10px;
  }

  @at-root #{&}__service-type-wrapper {
    margin-left: 35px;
  }

  @at-root #{&}__radio-btn {
    display: inline-block;
    position: relative;
    padding-left: 25px;
    margin: 0 5px 24px 0;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font: 1rem/1.43 "Lato", sans-serif;
    color: #767676;
    /* Hide the browser's default radio button */
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }
    /* Create a custom radio button */
    .checkmark {
      position: absolute;
      top: 2px;
      left: 0;
      height: 18px;
      width: 18px;
      background-color: #eee;
      border-radius: 50%;
    }
    /* On mouse-over, add a grey background color */
    &:hover input ~ .checkmark {
      background-color: #ccc;
    }
    /* When the radio button is checked, add a blue background */
    input:checked ~ .checkmark {
      background-color: #1bace4;
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Show the indicator (dot/circle) when checked */
    input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the indicator (dot/circle) */
    .checkmark:after {
      top: 5px;
      left: 5px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: white;
    }
  }

  @at-root #{&}__weeks-list li {
    list-style-type: none;
    color: #767676;
    font: 0.875rem/1.43 "Lato", sans-serif;
  }

  @at-root #{&}__section-title {
    display: flex;
    margin: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
    font-size: 1rem;
  }

  @at-root #{&}__section-icon {
    margin-right: 0.5rem;
    width: 14px;
    height: 22px;
  }

  @at-root #{&}__input-wrapper {
    padding: 0 0 22px 0;
    position: relative;
    .react-datepicker-wrapper {
      width: 100%;
    }
  }

  @at-root #{&}__input {
    background-color: #f6f6f6;
    border: 1px solid #000;
    border-radius: 4px;
    padding: 8px;
    height: 40px;
    color: #474747;
    font: 1rem/1.38 "Lato", sans-serif;
    width: 100%;
    outline: none;
  }
  
  @at-root #{&}__input-comment {
    min-height: 70px;
  }

  @at-root #{&}__input-label {
    margin-bottom: 3px;
    font: 1rem/1.38 "Lato", sans-serif;
    color: #767676;

    &.required::after {
      content: "*";
      color: #ea6457;
    }
  }
}

.my-account-page {
  overflow-x: hidden;
  background-color: #f6f6f6;
  .step-details {
    position: absolute;
    top: 24%;
    z-index: 1;
    left: 6%;
    @media (min-width: 320px) and (max-width: 767px) {
      position: unset;
    }
    @media (min-width: 768px) and (max-width: 1023px) {
      left: 0;
    }
    @media (min-width: 1920px) {
      left: 7%;
    }
  }
}

.my-account-page .eye-icon {
  right: 25px;
}

.my-account-page .registration-form {
  margin: 40px 0 0 0;
}

.my-account-page-tabs a {
  color: #ccc;
}
.my-account-page-tabs a.active,
.my-account-page-tabs a.active i {
  color: #fff;
}

@media (min-width: 320px) and (max-width: 767px) {
  .my-account-page .registration-form {
    margin: 140px 0 0 0;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .my-account-page .registration-form {
    margin: 40px 0 0 0;
    height: 100vh;
  }
}

.form__container {
  background-color: #f6f6f6;

  .form__wrapper {
    margin: 0 auto;
    padding: 0;
    max-width: 736px;
  }

  .form__header-wrapper {
    margin: 40px 0 0 0;
    position: relative;
    display: flex;
    align-items: flex-start;

    @media screen and (max-width: 736px) {
      margin: 40px 30px 0 30px;
    }

    .form__icon-link {
      margin-right: 32px;
      cursor: pointer;
    }

    .form__icon {
      width: 64px;
      height: 64px;
    }

    .form__title {
      font: bold 1.5rem/1.33 "Ubuntu", sans-serif;
      color: #474747;
      margin-bottom: 0.25rem;
      margin-top: 0;
    }

    .form__description {
      font: 1rem/1.38 "Lato", sans-serif;
      color: #767676;
      margin-bottom: 1.75rem;
    }
  }

  .form-input {
    border: 1px solid #000;
  }

  input[type="number"] {
    padding: 8px;
    appearance: textfield;
    -moz-appearance: textfield;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }

  .btn.btn-primary.upload-btn {
    padding: 10px 12px;
    .fa.upload-icon {
      padding: 0 !important;
      margin-right: 5px;
      vertical-align: middle;
    }
  }

  .form__main-content {
    background: #fff;
    min-height: calc(100vh - 111px);
    position: relative;

    form {
      min-height: calc(100vh - 190px);
    }

    .form__footer {
      box-shadow: 0 -4px 4px 0 rgba(0, 0, 0, 0.1);
      position: sticky;
      bottom: 0;
      padding: 1rem 2rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 1;
      background-color: white;

      .form__btn-submit {
        padding: 0.7em 0.9em;

        &:disabled {
          cursor: not-allowed;
          background-color: #ccc;
          border: 2px solid #ccc;
          color: #767676;
        }
      }

      .form__btn-cancel {
        font: bold 1rem/1.38 "Lato", sans-serif;
        color: #1bace4;
        cursor: pointer;
        border: 0;
      }

      .form__btn-loader {
        display: flex;
        justify-content: center;
      }
    }
  }
}

.floating-text-input__form-group {
  position: relative;
  margin: 0 auto;
  max-width: 572px;

  & + .floating-text-input__form-group {
    margin-top: 30px;
  }
}

.floating-text-input__form-label {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #474747;
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 1;
  transition: transform 150ms ease-out, font-size 150ms ease-out;

  &.focused {
    background-color: #fff;
  }

  &.required::after {
    content: "*";
    color: #ea6457;
    font-weight: bold;
  }
}

.floating-text-input__form-group--focussed .floating-text-input__form-label {
  transform: translateY(-125%);
  font-size: 0.75em;
  background-color: #fff;
}

.floating-text-input__form-input {
  position: relative;
  padding: 12px 5px 5px 10px;
  width: 100%;
  outline: 0;
  border: 0;
  box-shadow: 0 1px 0 0 #e5e5e5;
  transition: box-shadow 150ms ease-out;
  background-color: white;
  border-radius: 4px;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #474747;
  border: 1px solid #474747;
  &:focus {
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.1);
    outline: none;
    border: 1px solid #1bace4;
    background-color: #fff;
  }
}

.floating-text-input__form-input.floating-text-input__form-input--filled {
  border: 1px solid #32a728;
}

.custom-dropdown {
  width: 100%;
  // border-radius: 10px;
  background-color: white;
  &.open {
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    .dropdown-header {
      border-bottom: none;
    }
  }

  .dropdown-header {
    padding: 5px 15px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f6f6f6;
    border: 1px solid #999;
    color: #000;
    font-family: "Lato";
    font-size: 18px;
  }

  .dropdown-body {
    border-top: 1px solid #e5e8ec;
    display: none;
    position: absolute;
    background-color: white;
    width: 100%;
    z-index: 1;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    max-height: 275px;
    font-family: "Lato";
    padding: 0;
    list-style-type: none;
    background-color: #f6f6f6;
    overflow-y: scroll;
    &.open {
      display: block;
      border: 1px solid #999;
    }
  }

  .dropdown-item {
    padding: 5px 15px;
    color: #000;
    font-family: "Lato";
    font-size: 16px;
    border-bottom: 1px solid #ddd;
    
    &:last-child {
      border-bottom: none;
    }
    &:hover,
    &:focus {
      cursor: pointer;
      background-color: #1bace4;
      .site-title,
      .site-id {
        color: white;
      }
    }
  }

  .icon {
    font-size: 13px;
    color: #000;
    transform: rotate(90deg);
    transition: all 0.25s ease-in-out;
    &.open {
      transform: rotate(270deg);
    }
  }

  .site-title {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #474747;
    margin: 0;
  }

  .site-id {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: #767676;
    margin: 0;
    &.placeholder {
      margin: 11px 0;
      font-size: 16px;
      line-height: 22px;
    }
  }
}

.container {
  padding: 0;
}
.time-input-container {
  display: flex;
  .form-field {
    flex: 1;
    min-width: 0;
  }
  .closing-time-wrapper {
    flex: 0.9;
    margin-left: 5px;
    position: relative;
    .form-label {
      visibility: hidden;
    }
  }
}
.requests-priority-title {
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #767676;
  &::after {
    content: "*";
    color: #ea6457;
  }
}
.requests-priority-options-wrapper {
  margin-bottom: 23px;
}

#mapid {
  width: 100%;
  height: 270px;
  margin-bottom: 22px;
  .leaflet-container {
    height: 100%;
    z-index: 1;
  }
}

.jobsite__form-input {
  background-color: #f6f6f6;
  border: 1px solid #000;
  border-radius: 4px;
  padding: 8px;
  height: 40px;
  color: #474747;
  font: 1rem/1.38 "Lato", sans-serif;
  width: 100%;
}

@media (min-width: 320px) and (max-width: 767px) {
  .job-site-form {
    padding: 40px 16px;
  }
  .media {
    display: flex;
    align-items: flex-start;
    margin: 0 16px;
  }
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-outline-primary {
  border: 1px solid #ccc;
  background-color: #f6f6f6;
  color: #474747;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
}
.btn-outline-primary:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #1bace4;
  border-color: #1bace4;
}

.btn-check:checked + .btn-outline-primary,
.btn-check:checked + .btn-outline-primary:active {
  color: #fff;
  background-color: #1bace4;
  border-color: #1bace4;
}
.requests-priority-options-wrapper
  .btn-outline-primary:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #1bace4;
  border-color: #1bace4;
}

$quote-sent-status-bg-color: #4a90e2;
$quote-in-progress-bg-color: #ff9800;
$quote-won-bgn-color: #32a728;

.enquiry-tile {
  border: 0;
  border-top: 8px solid #4a90e2;
  box-shadow: 0px 2px 4px #d8d8d8;
  margin-bottom: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 0.25rem;
  flex: 1;
  padding: 10px;
  max-width: 400px;
  &:last-child {
    margin-left: 10px;
  }
  @at-root #{&}__wrapper {
    cursor: pointer;
    padding: 0;
    border-bottom: 0;
    display: flex;
    align-items: flex-start;
  }
  @at-root #{&}__icon {
    margin-right: 10px;
  }
  @at-root #{&}__details-wrapper {
    flex: 1 1;
  }
  @at-root #{&}__title-wrapper {
    display: flex;
    justify-content: space-between;
  }
  @at-root #{&}__title-id {
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #474747;
    margin: 0;
    padding: 0;
    display: inline-block;
  }
  @at-root #{&}__id {
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #767676;
    margin: 0 0 0 10px;
    padding: 0;
    display: inline-block;
  }
  @at-root #{&}__service-wrapper {
    margin: 0;
  }
  @at-root #{&}__service-label {
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0px;
    color: #767676;
    text-align: left;
  }
  @at-root #{&}__service {
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0px;
    color: #474747;
    text-align: left;
    margin-left: 0;
  }
  @at-root #{&}__sub-category-id {
  }
  @at-root #{&}__quantity-wrapper {
    margin: 0;
  }
  @at-root #{&}__quantity-label {
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    color: #767676;
  }
  @at-root #{&}__quantity {
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    color: #474747;
  }
  @at-root #{&}__no-data {
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #474747;
    margin: 0;
  }
  .enquiry-status {
    background: white;
    border-radius: 4px;
    color: white;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    padding: 3px 10px;
    text-align: center;
    height: 23px;
    min-width: 105px;
    margin: 5px 0;
    @media (min-width: 768px) {
      margin: 0 0 0 5px;
    }

    &.in-progress {
      background-color: $quote-in-progress-bg-color;
    }

    &.quote-sent {
      background-color: $quote-sent-status-bg-color;
    }

    &.won {
      background-color: $quote-won-bgn-color;
    }
    &.cacnceled {
      background-color: #ccc;
    }
  }
}

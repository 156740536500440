.view-site-container {
  display: flex;
  padding: 0;

  .site-details-container {
    background-color: #fff;
    height: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 24px;
    position: relative;
    padding-bottom: 60px;

    .modal-parent {
      z-index: 100000;
    }

    @media (min-width: 992px) {
      padding-left: 85px;
      padding-right: 56px;
      padding-top: 29px;
    }
  }
}

.quote-item-wrapper {
  border: 1px solid #f6f6f6;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px #ededed;
  border-radius: 4px;
  margin-bottom: 16px;
  &:first-child {
    margin-top: 8px;
  }

  .quote-item-header {
    background: #ffffff;
    border: 1px solid #f6f6f6;
    box-sizing: border-box;
    border-radius: 4px 4px 0px 0px;
    border-top: 8px solid #4a90e2;
    display: flex;
    align-items: flex-start;
    padding: 10px;
    cursor: pointer;
    .quote-item-header__icon {
      width: 40px;
    }
    .quote-item-header__wrapper {
      margin-left: 10px;
      width: 100%;
    }
    .quote-item-header__name-id-wrapper,
    .quote-item-header__title-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
    .quote-item-header__name {
      font-family: Ubuntu;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: left;
      color: #474747;
      display: inline-block;
      margin: 0;
    }
    .quote-item-header__id {
      font-family: Ubuntu;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: left;
      color: #767676;
      display: inline-block;
      margin: 0 0 0 4px;
    }
    .quote-item-header__status {
      font-family: Lato;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: 0px;
      text-align: center;
      color: #fff;
      padding: 2px 8px;
      border-radius: 4px;
      margin-left: 5px;
      @at-root #{&}--submitted {
        background-color: #4a90e2;
      }
      @at-root #{&}--validated {
        background-color: #32a728;
      }
      @at-root #{&}--cancelled {
        background-color: #cccccc;
      }
      @at-root #{&}--lost {
        background-color: #9b3200;
      }
    }
    .quote-item-header__jobsite-name {
      font-family: Lato;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;
      color: #767676;
    }
    .quote-item-header__enquiries {
      font-family: Lato;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;
      color: #474747;
      margin-left: 4px;
    }
  }

  .quote-item-details {
    max-height: 0;
    visibility: hidden;
    opacity: 0;
    transition: all 0.35s ease;

    .quote-item-details__requests {
      list-style-type: none;
      padding: 10px;
      margin: 0;
      background-color: #f6f6f6;
    }
    .quote-item-details__request-item {
    }
    .quote-item-details__request-item-list {
      list-style-type: none;
      padding: 0;
    }
    .quote-item-details__request-item-info {
      border-bottom: 1px solid #ccc;
      margin-bottom: 10px;
      padding-bottom: 10px;
    }
    .quote-item-details__request-item-title {
      font-family: Lato;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: left;
      color: #474747;
      margin-bottom: 5px;
    }
    .quote-item-details__request-item-details {
      list-style-type: none;
      padding: 0 0 0 10px;
    }
    .quote-item-details__request-item-details-row {
      &.dot::before {
        content: "\A";
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #ccc;
        display: inline-block;
      }
      .quote-item-details__request-item-details-row-wrapper {
        display: inline-flex;
        vertical-align: middle;
        width: 100%;
        flex-wrap: wrap;
      }
      &.dot .quote-item-details__request-item-details-row-wrapper {
        margin-left: 10px;
        width: 94%;
      }
      .quote-item-details__request-item-details-row-wrapper--wrap {
        flex-wrap: wrap;
      }
      .quote-item-details__request-item-details-info-container {
        width: 100%;
        &.dot::before {
          content: "\A";
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: #ccc;
          display: inline-block;
        }
        &.dot .quote-item-details__request-item-details-info-wrapper--dot {
          display: inline-flex;
          justify-content: space-between;
          width: 94%;
          vertical-align: text-top;
          margin-left: 10px;
        }
      }
      .quote-item-details_request-item-details-info-wrapper {
        display: inline-block;
        width: 94%;
        vertical-align: text-top;
        margin-left: 10px;
      }
      .quote-item-details__request-item-details-info-wrapper {
        display: flex;
        justify-content: space-between;
      }

      .quote-item-details__request-item-details-info {
        font-family: Lato;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0px;
        text-align: left;
      }
      .quote-item-details__request-item-details-info-service {
        color: #474747;
      }
      .quote-item-details__request-item-details-info-product {
        color: #767676;
      }
      .quote-item-details__request-item-details-info-price {
        font-family: Lato;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 0px;
        text-align: right;
        color: #474747;
      }
      .quote-item-details__request-item-details-info-transport {
        font-family: Lato;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0px;
        text-align: left;
        margin: 0;
        color: #767676;
      }
    }
  }
  .quote-item-details--show {
    max-height: 1200px;
    visibility: visible;
    opacity: 1;
  }
  .quote-item-pdf-wrapper {
    padding: 13px;
    text-align: right;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: right;
    .quote-item-pdf-icon {
      margin-right: 12px;
      width: 22px;
      vertical-align: middle;
      cursor: pointer;
    }
    span {
      cursor: pointer;
    }
  }
}

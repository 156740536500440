.v3-contact-form__container {
  padding: 32px 16px;
  overflow-y: auto;
  height: calc(100vh - 200px);
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: #c5c5c5;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #888;
  }

  @media screen and (min-width: 768px) {
    padding: 32px 40px 88px 40px;
  }

  .client-type-options-container {
    margin-bottom: 54px;
    .custom-radio-options__title {
      font-family: Ubuntu;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      text-align: center;
      color: #000000;
      margin: 0 0 10px 0;
    }
  }

  .waste-quantity-options-container {
    margin-bottom: 48px;
    .custom-radio-options__title {
      font-family: Ubuntu;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      text-align: center;
      color: #000000;
      margin: 0 0 40px 0;
    }
  }

  .client-type-options-container,
  .waste-quantity-options-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .client-type-options-list {
    .custom-radio-options__option-name {
      font-weight: bold;
    }
    .custom-radio-options__options-item {
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
      background-color: white;
      border: 1px solid #e5e5e5;
      &.active {
        background-color: #c9e8fb;
      }
      @media screen and (max-width: 768px) {
        min-width: 130px;
      }
    }
  }

  .waste-quantity-options-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: row;
    @media screen and (min-width: 768px) {
      grid-template-columns: repeat(4, 1fr);
    }

    .custom-radio-options__options-item {
      margin: 0 2px 6px 0;
      @media screen and (min-width: 768px) {
        width: 178px;
      }
      &:last-child {
        margin: 0 0 6px 0;
      }
      &:first-child {
        .custom-radio-options__option-icon {
          width: 21px;
        }
      }
      &:nth-child(2) {
        .custom-radio-options__option-icon {
          width: 42px;
        }
      }
    }
    .custom-radio-options__option-name {
      font-weight: bold;
    }
    .custom-radio-options__options-item {
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
      background-color: white;
      border: 1px solid #e5e5e5;
      &.active {
        background-color: #c9e8fb;
      }
      @media screen and (min-width: 320px) and (max-width: 400px) {
        min-width: 142px;
      }
    }
  }

  .enquiry-form__title {
    font-family: Ubuntu;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    margin: 0 0 32px 0;
  }

  .form-field {
    padding: 0;
    margin-bottom: 48px;
  }
}

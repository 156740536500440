.file-input-label {
  vertical-align: text-top;
  margin-right: 5px;
}

.tooltip-container {
  display: inline-block;
  position: relative;

  .tooltip-icon {
    width: 18px;
    height: 18px;
    cursor: pointer;
  }

  .tooltip-message {
    font-size: 16px;
    display: none;
    font-size: 16px;
    font-family: "Lato";
    color: #474747;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #ddd;
    box-shadow: 1px 1px 1px 1px #ddd;
    position: absolute;
    width: 300px;
    overflow: hidden;
    z-index: 1;
  }

  .tooltip-icon:hover + .tooltip-message {
    display: inline-block;
  }
}

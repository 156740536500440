$font-primary: Lato;
$card-bg-color: white;
$card-strip-color: #979797;
$card-radius: 4px;

$text-color-primary: #474747;
$text-color-secondary: #767676;
$text-color-tertiary: #1bace4;

$line-separator-bg: #d8d8d8;
$box-shadow-color: rgba(0, 0, 0, 0.25);

$white: #fff;
$salmon: #ff9664;
$orange: #ff5200;
$green: #0a6800;
$lightBlue: #1bace4;
$darkBlue: #024d7f;
$linkColor: #004c7f;
$inputBorderColor: #4a90e2;
$disabledTextColor: #d0cdcd;
$errorBtnTextColor: #979797;
$errorPopupShadowColor: #00000066;

%flex-center {
  align-items: center;
  display: flex;
  justify-content: center;
}

%base-font {
  font-family: $font-primary;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0px;
}

%flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

@function rem($size) {
  @return $size / 16px * 1rem;
}

.error-popup {
  background-color: $errorPopupShadowColor;
  font-family: $font-primary;
  font-style: normal;
  height: 100vh;
  left: 0;
  letter-spacing: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10000;

  @at-root #{&}__box {
    background-color: white;
    border-radius: 5px;
    margin: 10% auto 0 auto;
    padding-bottom: rem(30px);
    width: rem(500px);
    @media screen and (max-width: 767px) {
      width: 95%;
    }
  }

  @at-root #{&}__title {
    background-color: red;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: white;
    font-size: rem(18px);
    font-weight: 700;
    line-height: rem(20px);
    margin: 0;
    padding: rem(20px);
    text-align: left;
  }

  @at-root #{&}__content-wrapper {
    padding: rem(20px);
  }

  @at-root #{&}__content {
    color: $text-color-primary;
    font-size: rem(16px);
    font-weight: 700;
    line-height: rem(22px);
    margin: 0;
    text-align: left;
  }

  @at-root #{&}__btn-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-right: rem(30px);
    margin-top: rem(30px);
  }

  @at-root #{&}__btn {
    align-items: center;
    background-color: $text-color-secondary;
    border-radius: 4px;
    border: 1px solid $text-color-secondary;
    color: white;
    cursor: pointer;
    display: flex;
    font-size: rem(14px);
    font-weight: bold;
    line-height: rem(16px);
    margin-left: rem(20px);
    padding: rem(11px) rem(16px);
    text-align: center;

    @at-root #{&}--ok {
      background-color: $errorBtnTextColor;
      border: 1px solid $errorBtnTextColor;
      color: white;
    }
  }
}

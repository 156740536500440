.list-quotes {
  margin-bottom: 0;
  @media (min-width: 320px) and (max-width: 767px) {
    margin-bottom: 10px;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin: 5px 0;
  }
}

.site-data-container {
  padding-left: 60px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #f6f6f6;

  .enquiry-tile-container {
    display: flex;
  }
  
  .more-enquiries {
    display: flex;
    justify-content: center;
    align-items: center;
    .more-enquiries__text-wrapper {
      text-align: center;
    }
    .more-enquiries__count {
      color: #474747;
      font-family: Ubuntu;
      font-size: 20px;
      font-style: normal;
      font-weight: 100;
      line-height: 28px;
      letter-spacing: 0px;
      text-align: center;
      display: block;
    }
    .more-enquiries__text {
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: left;
      color: #1bace4;
    }
  }
}

.loader-wrapper{
    text-align: center;
    padding: 25px 0;
}

.error-wrapper{
    font-size: 16px;
    font-family: 'Lato';
    padding: 25px 0;
    text-align: center;
    color: #474747;
}

.no-sites-container{
    display: flex;
    justify-content: center;
    margin-top: 170px;
}
.translate .dropdown-menu {
  min-width: auto;
  width: 46px;
}
.translate .dropdown-menu.show {
  right: 10px;
  text-align: center;
  border-radius: 0;
  padding: 4px 0;
}
.translate .dropdown-toggle::after {
  position: relative;
  top: 4px;
  right: 4px;
}
.translate button {
  outline: none;
  box-shadow: none;
}
.translate .dropdown-item {
  padding: 0.1rem 0.4rem;
  margin: 3px 0;
}

.dropdown-item {
  .flag {
    width: 28px;
  }
}

.bottom-lang {
  background: none;
  margin-right: 0rem;
}

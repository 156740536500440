.dashboard-container {
  display: flex;
  padding: 0;
  background-color: #f6f6f6;
  @media (min-width: 768px) {
    background-color: white;
  }

  #content {
    width: 100%;
    padding: 0;
    margin: 0 auto;
    position: relative;
  }
  .modal-parent {
    z-index: 999999;
  }
}

// .modal-parent {
//   z-index: 999999;
// }

// a[aria-expanded="true"] {
//   color: #c9e8fb;
//   background: rgba(255, 255, 255, 0.1);
// }

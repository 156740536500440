.dashboard-details-container {
  padding: 24px 0 24px 0;
  max-width: 1440px;
  margin: 0 auto;
  > div {
    padding: 0;
  }

  @media (min-width: 768px) {
    padding-left: 10px;
    padding-right: 10px;
  }

  @media (min-width: 1440px) {
    padding-left: 80px;
    padding-right: 60px;
  }

  .enquiries-container {
    margin: 0;
    display: none;
    @media (min-width: 1200px) {
      display: flex;
    }
    @media (max-width: 768px) {
      display: flex;
      margin-top: 16px;
    }
  }

  .show-desktop {
    display: none;
    @media (min-width: 1200px) {
      display: block;
    }
    @media (max-width: 768px) {
      display: block;
    }
  }

  .show-mobile {
    display: none;
    @media (min-width: 768px) and (max-width: 1200px) {
      display: block;
    }
  }
}

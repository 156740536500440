.calendar-icon {
  position: absolute;
  right: 23px;
  bottom: 33px;
}

.custom-datepicker-calendar {
  background-color: white;
  font-family: "Lato";
  border: 1px solid #fff;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 12px 0;

  .react-datepicker__navigation {
    top: 22px;
  }
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
    background-color: white;
  }
  .react-datepicker__navigation--previous {
    background-image: url("../../../assets/shared/ic-arrow-left.svg");
    background-size: 30px;
    background-position: center;
    width: 30px;
    height: 30px;
    border: none;
  }
  .react-datepicker__navigation--next {
    background-image: url("../../../assets/shared/ic-arrow-right.svg");
    background-size: 30px;
    background-position: center;
    width: 30px;
    height: 30px;
    border: none;
  }
  .react-datepicker__header {
    background-color: white;
    border-bottom: none;
    border-top-left-radius: 0.3rem;
    padding-top: 8px;
    position: relative;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: center;
    color: #474747;
    padding: 0;
  }
  .react-datepicker__current-month {
    padding: 12px 6px;
    display: inline-block;
  }
  .react-datepicker__header__dropdown--select {
    display: inline-block;
    .react-datepicker__year-select {
      width: 64px;
      border: none;
      outline: none;
      font-size: 1rem;
      font-family: Lato;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: left;
      color: #474747;
    }
  }
  .react-datepicker__day-names {
    border-top: 1px solid #ededed;
  }
  .day-class {
    border: none;
    background: #fff;
    font-weight: normal;
    color: #474747;
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: center;
    padding: 5px 0;
  }
  .react-datepicker__day--selected {
    border: 1px solid #004c7f;
    background: #004c7f;
    color: #fff;
    border-radius: unset;
  }
  .react-datepicker__day--today {
    border: 1px solid #767676;
    background: #767676;
    color: #fff;
    border-radius: unset;
  }
  .react-datepicker__day-name {
    padding: 0.7em 0.3em 0 0.3em;
    border: 0;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: center;
    color: #767676;
  }
  .react-datepicker__month {
    margin: 1rem;
  }
  .react-datepicker__day--outside-month {
    opacity: 0.35 !important;
  }
}

.react-datepicker-wrapper {
  width: 100%;
}

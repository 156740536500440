.call-order-form {
  padding: 40px 96px;
  @media (min-width: 320px) and (max-width: 767px) {
    padding: 40px 30px;
  }
}

.call-order-form__site-selector-container,
.call-order-form__request-selector-container {
  padding: 16px;
  margin-bottom: 1.5rem;
  border: 0;
  display: flex;
  align-items: flex-start;
  background-color: #f6f6f6;
  border-radius: 4px;
}

.call-order-form__site-icon,
.call-order-form__request-icon {
  margin: 0 0.5rem 0 0;
}

.call-order-form__section-title {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #004c7f;
}

.call-order-form__site-selector-wrapper,
.call-order-form__request-selector-wrapper {
  flex: 1 1;
}

.call-order-form__input {
  background-color: #f6f6f6;
  border: 1px solid #f6f6f6;
  border-radius: 4px;
  padding: 8px 28px 8px 8px;
  height: 40px;
  color: #474747;
  text-transform: capitalize;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  margin: 0;
  padding: 0.5rem;
  width: 100%;
  border: 1px solid #000;
  outline: none;
}
.call-order-form__input--comment {
  min-height: 98px;
}
.call-order-form__input-wrapper {
  padding: 0 0 22px 0;
  position: relative;
  .calendar-icon {
    right: 12px;
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker-popper {
    z-index: 2;
  }
}
.call-order-form__input-label {
  margin-bottom: 3px;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #474747;

  &.required::after {
    content: "*";
    color: #ea6457;
  }
}

.call-order-form__input-label--hide {
  display: none;
}

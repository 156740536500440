body {
  margin: 0;
  font-family: "Ubuntu", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2 {
  font: 1.5rem/1.3 "Ubuntu", sans-serif;
  font-weight: 500;
  color: #474747;
}

h2 {
  font: 1.25rem/1.4 "Ubuntu", sans-serif;
}

p,
.form-label,
.checkbox-label {
  font: 1rem/1.38 "Lato", sans-serif;
  color: #767676;
}

button {
  background-color: transparent;

  &:focus {
    outline: none;
  }
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: center;

  &.disabled {
    opacity: 0.6;
    border: 1px solid #ccc;
    cursor: not-allowed;
  }
}

.btn {
  &.btn-primary {
    border-radius: 4px;
    font: bold 1rem/1.38 "Lato", sans-serif;
    padding: 0.8rem 4.3em;
    cursor: pointer;
    box-shadow: none;
  }
}

.btn-primary {
  &.btn-blue,
  &.btn-transparent:hover,
  &.btn-transparent:not(:disabled):not(.disabled):active {
    border: 2px solid #1bace4;
    background-color: #1bace4;
    color: #fff;
  }

  &.btn-transparent {
    border: 2px solid #1bace4;
    background-color: #fff;
    color: #1bace4;
  }
  &.disabled {
    background-color: #ccc;
    border: 2px solid #ccc;
    color: #474747;
    opacity: 1;
    cursor: not-allowed;
  }
  &.btn-gray {
    background-color: #f6f6f6;
    border-radius: 5px;
    border: 1px solid #004c7f;
  }
  &.btn-dark-gray {
    background-color: #ededed;
    border-radius: 4px;
    border: 1px solid #ccc;
    color: #474747;
  }
  &.btn-gray:hover {
    color: #004c7f;
  }
}

.button-transparent {
  background-color: 2px solid #1bace4;
}

.crsr-ptr {
  cursor: pointer;
}

a:focus {
  outline: none;
}

.form-field {
  padding: 0 0 22px 0;
  position: relative;

  &.disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
}

.form-label {
  margin-bottom: 3px;

  &.required::after {
    content: "*";
    color: #ea6457;
  }
}

textarea {
  &.form-input {
    min-height: 70px;

    &.input-height {
      min-height: 40px;
    }
  }
}

.form-input {
  background-color: #f6f6f6;
  border: 1px solid #f6f6f6;
  border-radius: 4px;
  padding: 8px 28px 8px 8px;
  height: 40px;
  color: #474747;
  font: 1rem/1.38 "Lato", sans-serif;

  &:focus {
    background-color: #fff;
    outline: none;
    border: 1px solid #1bace4;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font: 1rem/1.38 "Lato", sans-serif;
    color: #ccc;
  }

  &::placeholder {
    font: 1rem/1.38 "Lato", sans-serif;
    color: #ccc;
  }

  &::-webkit-input-placeholder {
    /* Edge */
    font: 1rem/1.38 "Lato", sans-serif;
    color: #ccc;
  }
}

.checkbox-input {
  width: 18px;
  height: 16px;
  margin: 0 3px 0 0;
  top: 4px;
}

.drop-menu {
  color: #004c7f;
  margin-right: 10px;
}

.blue-text {
  color: #1bace4;
}

.blue-bg {
  background: #1bace4 !important;
}

.badge {
  background-color: #fff;
  color: #ccc;
  border: 1px solid #ccc;
  height: 40px;
  width: 40px;

  .number {
    top: 5px;
    font: bold 1rem/1.38 "Lato", sans-serif;
  }
}

input {
  &:-webkit-autofill {
    box-shadow: 0 0 0 50px #f6f6f6 inset; /* Change the color to your own background color */
    -webkit-text-fill-color: #474747;

    &:focus {
      box-shadow: 0 0 0 50px white inset; /*your box-shadow*/
      -webkit-text-fill-color: #474747;
    }
  }
}

input[type="date"] {
  text-transform: uppercase;
  padding: 8px;

  &::-webkit-input-placeholder {
    /* Edge */
    font: 1rem/1.38 "Lato", sans-serif;
    color: #ccc;
  }
}

.clear {
  clear: both;
}

.fontLato14 {
  font: 0.875rem/1.43 "Lato", sans-serif !important;
}

.fontLato12 {
  font: 0.75rem/1.33 "Lato", sans-serif !important;
}

.fontLato16 {
  font: 1rem/1.38 "Lato", sans-serif !important;
}

.fontUbuntu20 {
  font: 1.25rem/1.4 "Ubuntu", sans-serif !important;
}

.gray-text {
  color: #767676;
}

.gray-darker-text {
  color: #474747;
}

.dark-blue-text {
  color: #004c7f;
}

.card {
  border: 0;
  border-top: 8px solid #4a90e2;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2);
}

.card-header {
  background-color: transparent;
  margin: 21px 24px 12px 24px;
  border-bottom: 1px solid #ccc;
}

.media-header .right-text,
.card-header .right-text {
  color: #1bace4;
}

.loader {
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 999999999999;
  background: rgba(0, 0, 0, 0.5);
  min-height: 100vh;
  height: 100%;

  & > div {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

.card.success {
  border-top: 8px solid #7ac943;
}

.success-bg {
  background-color: #7ac943;
  color: #fff;
}

.primary-bg {
  background-color: #4a90e2;
  color: #fff;
}

.status-badge {
  padding: 2px 4px;
  border-radius: 4px;
}

/*****MODAL *******/

.modal-parent {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  z-index: 1072;
}

.modal-title {
  font: bold 1rem/1.38 "Lato", sans-serif;
}
.modal-body {
  font: 0.875rem/1.33 "Lato", sans-serif;
}

//Error Boundary classes.
.error-box {
  width: 920px;
  color: #000;
  font-family: Roboto;
  font-weight: 300;
  margin: 5% auto;
  box-shadow: 4px 4px 10px #ccc;
  display: flex;

  .error-box__logo {
    width: 420px;
    padding: 2%;
    background-color: #fff;
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .error-box__details-title {
    font-size: 120px;
    line-height: 46px;
    margin-bottom: 40px;
    color: #ff5200;
    font-weight: normal;
  }

  .error-box__details {
    width: 500px;
    background-color: #004c7f;
    color: #fff;
    padding: 2%;
    min-height: 300px;
    text-align: center;
  }

  .error-box__details-description {
    font-size: 18px;
    font-weight: lighter;
  }
}

//File input
.upload-files-table {
  max-height: 200px;

  .file-name {
    font: 0.875rem/1.43 "Lato", sans-serif !important;
    word-break: break-word;
    color: #474747;
  }
}

.custom-checkbox {
  display: inline-block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
  }
}

/* On mouse-over, add a grey background color */
.custom-checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.custom-checkbox input:checked ~ .checkmark {
  background-color: #1bace4;
}

/* Create the checkmark/indicator (hidden when not checked) */
.custom-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.custom-checkbox .checkmark:after {
  left: 8px;
  top: 4px;
  width: 5px;
  height: 11px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.custom-checkbox.disabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}

.custom-checkbox.tender-checkbox {
  padding-left: 28px !important;
}
.custom-checkbox.tender-checkbox .checkmark {
  top: 12px;
  left: 6px;
  height: 16px;
  width: 16px;
}

.wrapper {
  display: flex;
  width: 100%;
}

ul ul a {
  font-size: 0.9em !important;
  padding-left: 30px !important;
  background: #6d7fcc;
}

a.download {
  background: #fff;
  color: #7386d5;
}

a.article,
a.article:hover {
  background: #6d7fcc !important;
  color: #fff !important;
}

.rdrStartEdge,
.rdrDay,
.rdrInRange,
.rdrEndEdge {
  color: #1bace4 !important;
}
.rdrDay.rdrDayHovered {
  border-color: #1bace4 !important;
}

.mapboxgl-canvas-container {
  height: 300px;
}
.mapboxgl-canvas {
  width: 100% !important;
  position: absolute;
  left: 0;
  right: 0;
}

.rw-widget-picker {
  max-height: 100px;
  overflow: auto;
}
.rw-multiselect-tag {
  background-color: #1bace4;
  border: 1px solid #1bace4;
  color: #fff;
}
.rw-multiselect-tag-label,
.rw-list-option {
  font: 0.875rem/1.43 "Lato", sans-serif;
  color: #767676;
}
.rw-list-optgroup {
  font: 1rem/1.38 "Lato", sans-serif;
  color: #474747;
}
.rw-multiselect-tag-label {
  color: #fff;
}
.rw-list-option:hover,
.rw-list-option-create:hover,
[data-intent="keyboard"] .rw-list-option.rw-state-focus:not(.rw-state-selected),
[data-intent="keyboard"]
  .rw-state-focus.rw-list-option-create:not(.rw-state-selected) {
  background-color: #1bace4;
  border-color: #1bace4;
  color: #fff;
}
.rw-widget-input {
  color: #474747;
  background-color: #f6f6f6;
  box-shadow: none;
  border: 0;
}

.btn.btn-primary.btn-sm {
  font-size: 14px;
  padding: 0.3rem;
}
.btn.btn-primary:disabled {
  cursor: not-allowed;
}
.error-text {
  font: 0.875rem/1.43 "Lato", sans-serif !important;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

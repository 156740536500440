.onboarding-v3__container {
  background: #004c7f;
  height: 100vh;
}

.onboarding-v3__wrapper {
  background-color: white;
  max-width: 816px;
  margin: 0 auto;
  height: 100vh;
}

.onboarding-v3__footer {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
  width: 100%;
  max-width: 816px;
  z-index: 1;
  background-color: white;
}

.email-unicity {
  .close {
    display: none;
  }
}

.custom-selectbox {
  @at-root #{&}__option-display {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    .custom-selectbox__option-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  @at-root #{&}__options-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    border: 1px solid black;
    border-top: 0;
    position: absolute;
    border-radius: 5px;
    width: 100%;
    z-index: 2;
    background-color: #f6f6f6;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
    max-height: 250px;
    overflow-y: scroll;
  }

  @at-root #{&}__options-item {
    border-bottom: 1px solid #ddd;
    padding: 8px;
    font-size: 16px;
    font-family: "Lato";
    line-height: 22px;
    color: #474747;

    &:last-child {
      border-bottom: none;
    }
    &:hover,
    &:focus {
      cursor: pointer;
      color: white;
      background-color: #1bace4;
    }
  }

  .icon {
    font-size: 13px;
    color: #000;
    transform: rotate(0);
    transition: all 0.25s ease-in-out;
    &.open {
      transform: rotate(180deg);
    }
  }
}

$base-font: Lato;
$text-color-primary: #474747;
$text-color-secondary: #767676;
$text-color-tertiary: #1bace4;

$border-color: #ededed;

@function rem($size) {
  @return $size / 16px * 1rem;
}

.contextual-assistant {
  @at-root #{&}__container {
    margin-right: 0;
    @media (min-width: 768px) {
      margin-right: rem(16px);
    }
  }

  @at-root #{&}__icon-wrapper {
    display: none;
    justify-content: flex-end;
    margin-right: rem(24px);
    @media (min-width: 768px) {
      display: flex;
    }
  }

  @at-root #{&}__icon {
    display: block;
    width: rem(194px);
  }

  @at-root #{&}__details-wrapper {
    background-color: rgba(201, 232, 251, 0.5);
    padding: rem(16px) rem(24px) rem(24px);
    margin: 0;
    border-radius: 4px;
    margin-bottom: rem(24px);
    position: relative;
    @media (min-width: 768px) {
      margin-right: rem(16px);
      top: -46px;
      margin-bottom: 0;
    }
    @media (min-width: 320px) and (max-width: 479px) {
      background-color: #c9e8fb;
      padding: rem(16px) rem(24px);
      margin: 0;
    }
  }

  @at-root #{&}__description {
    color: $text-color-primary;
  }

  @at-root #{&}__user-name {
    color: #004c7f;
    font-family: Ubuntu;
    font-size: rem(20px);
    font-style: normal;
    font-weight: bold;
    line-height: rem(28px);
    letter-spacing: 0;
    text-align: left;
  }

  @at-root #{&}__link {
    font-family: $base-font;
    font-size: rem(16px);
    font-style: normal;
    font-weight: bold;
    line-height: rem(22px);
    letter-spacing: 0;
    text-align: left;
    color: $text-color-tertiary;
    cursor: pointer;
    &:hover {
      color: #1bace4;
    }
  }
}

#sidebar {
  display: block;
  visibility: hidden;
  width: 60px;
  position: sticky;
  top: 0;
  height: 100vh;
  z-index: 11000;
  background: #004c7f;
  color: #fff;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  transition: transform 250ms linear, width 250ms linear;
  will-change: transform;

  @media (max-width: 767px) {
    width: 100%;
    position: fixed;
    display: block;
    transform: translateX(-100%);
    visibility: hidden;
  }

  @media (min-width: 768px) {
    visibility: visible;
  }

  .sidebar__menu-list {
    padding-left: 0;
    list-style: none;
    margin: 0;
    width: 100%;
    li {
      cursor: pointer;
    }
  }

  .menu-pad {
    display: inline-block;
    min-width: 175px;
    opacity: 0;
    transition: opacity 250ms ease-in 50ms;
    visibility: hidden;
    padding-left: 12px;
    @media (max-width: 767px) {
      display: inline-block;
      opacity: 1;
    }
  }

  .show-mobile-menus.bottom .hide-text {
    display: none;
    @media (max-width: 767px) {
      display: inline-block;
    }
  }

  .my-account-btn {
    margin: 0;
  }

  &.active {
    width: 285px;

    .menu-pad {
      display: inline-block;
      opacity: 1;
      visibility: visible;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .show-mobile-menus.bottom .hide-text {
      display: inline-block;
    }

    @media (max-width: 992px) {
      width: 270px;
    }
    @media (max-width: 767px) {
      width: 100%;
      display: block;
      transform: translateX(0);
      visibility: visible;
    }
  }

  .sidebar-header {
    padding: 18px;
    background: #004c7f;
    border-bottom: 1px solid rgba(246, 246, 246, 0.5);
  }

  ul.bottom.components {
    bottom: 0;
  }

  ul p {
    color: #fff;
    padding: 10px;
  }

  ul li a,
  ul li > .nav-link {
    padding: 10px 16px;
    color: #c9e8fb;
    font: 1rem/1.38 "Lato", sans-serif;
    display: block;
    border-bottom: 1px solid rgba(246, 246, 246, 0.5);
  }

  ul li > .nav-link {
    display: flex;
    height: 43px;
    &.active {
      background-color: #1bace4;
    }
    &:disabled {
      background-color: #bbb;
      color: #666;
    }
  }

  ul.bottom li a {
    border-top: 1px solid rgba(246, 246, 246, 0.5);
    border-bottom: 0;
    display: flex;
  }

  ul li i {
    padding: 0;
    font-size: 23px;
  }

  ul li a:hover,
  ul li a.active,
  ul li > .nav-link:active,
  ul li > .nav-link:hover {
    color: #c9e8fb;
    background: rgba(255, 255, 255, 0.1);
  }
  ul li > .nav-link:active,
  ul li > .nav-link:hover,
  ul.bottom li a:active,
  ul.bottom li a:hover {
    color: #c9e8fb;
    background: #1bace4;
  }

  ul li.active > a {
    color: #c9e8fb;
    background: rgba(255, 255, 255, 0.1);
  }

  .show-mobile-menus {
    display: inline;
  }

  .mobile-menu {
    display: none;
    padding: 15px;
    border-bottom: 1px solid rgba(246, 246, 246, 0.5);
    .logo {
      cursor: pointer;
      margin-right: 1rem;
      vertical-align: middle;
      border-style: none;
    }
  }

  .sidebar__mobile-header {
    display: flex;
    justify-content: space-between;
    padding: 16px 30px 16px 16px;
    @media (min-width: 768px) {
      display: none;
    }
  }

  .show-desk-menus {
    display: none;

    @media (max-width: 767px) and (min-width: 320px) {
      display: inline;
    }

    .dropdown-toggle {
      &::after {
        display: none;
      }
    }

    .flag-div {
      background-color: #f6f6f6;
      margin-bottom: 32px;
      img {
        width: 32px;
        height: 32px;
        top: 0;
      }
      &::after {
        display: none;
      }
    }

    .translate {
      .dropdown-menu.show {
        right: 0;
        text-align: center;
        padding: 4px 0;
        border-radius: 4px;
      }

      .dropdown-item {
        padding: 0.3rem 0;
        margin: 0;
        img {
          width: 22px;
          height: 22px;
        }
      }
    }

    .my-account-btn {
      width: 22px;
      height: 22px;
      padding: 1px;
    }
  }

  .my-account-bottom {
    height: 25px;
    width: 25px;
    padding: revert;
    margin-left: 0;
    color: #fff;
    background: #1bace4;
    border: 0;
    text-transform: uppercase;
    &.dropdown-toggle::after {
      border: none;
    }
    .default-user {
      font-size: 18px;
    }
  }
}

.my-account-btn {
  height: 32px;
  width: 32px;
  padding: 6px;
  color: #fff;
  background: #1bace4;
  border: 0;
  font: 0.875rem/1.43 "Lato", sans-serif;
  margin-right: 0.5rem;
  border-radius: 50%;
  text-transform: uppercase;
  &.dropdown-toggle::after {
    border: none;
  }
}

@media (max-width: 767px) and (min-width: 320px) {
  #sidebar.active ul li a,
  #sidebar ul li > .nav-link,
  #sidebar.active .sidebar-header {
    text-align: left;
  }
  #sidebar .show-mobile-menus {
    display: none;
  }
  #sidebar ul.bottom.components {
    right: 20px;
  }
}

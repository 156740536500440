$base-font: Lato;
$text-color-primary: #474747;
$text-color-secondary: #767676;
$text-color-tertiary: #1bace4;

$quote-sent-status-bg-color: #4a90e2;
$quote-in-progress-bg-color: #ff9800;
$quote-won-bgn-color: #32a728;
$border-color: #ededed;

.my-enquiries {
  font-family: Lato;
  font-style: normal;
  letter-spacing: 0px;
  margin-left: 0;

  @media (min-width: 1200px) {
    margin-left: 16px;
  }

  .dashboard-enquiries {
    margin: 0;
    padding: 0;

    .enquiry-item {
      align-items: flex-start;
      border-bottom: 1px solid $border-color;
      cursor: pointer;
      display: flex;
      padding: 1rem 0 1rem 1rem;
      background-color: white;

      &:last-child {
        border-bottom: none;
      }

      .enquiry-img {
        margin-right: 8px;
        width: 40px;
      }

      .enquiry-details-wrapper {
        width: 100%;

        .title-status-wrapper {
          display: flex;
          justify-content: space-between;
          margin: 0;
          width: 100%;
          @media (max-width: 768px) {
            flex-wrap: wrap;
            padding-right: 1rem;
          }

          .title-wrapper {
            display: flex;
            margin: 0;

            .enquiry-name {
              color: $text-color-primary;
              font-size: 1rem;
              font-weight: bold;
              line-height: 22px;
              margin: 0;
              text-transform: uppercase;
            }
            .enquiry-id {
              font-family: $base-font;
              font-size: 14px;
              font-weight: 700;
              line-height: 20px;
              text-align: left;
              color: $text-color-secondary;
              margin: 0 8px;
            }

            .sub-cat-icon {
              width: 30px;
              height: 30px;
            }
          }

          .quote-status {
            background: white;
            border-radius: 4px;
            color: white;
            font-size: 12px;
            font-weight: 700;
            line-height: 16px;
            padding: 3px 10px;
            text-align: center;
            height: 23px;
            min-width: 105px;
            margin: 5px 0;
            @media (min-width: 768px) {
              margin: 0 0 0 5px;
            }

            &.in-progress {
              background-color: $quote-in-progress-bg-color;
            }

            &.quote-sent {
              background-color: $quote-sent-status-bg-color;
            }

            &.won {
              background-color: $quote-won-bgn-color;
            }
          }
        }

        .enquiry-description {
          color: $text-color-secondary;
          font-size: 12px;
          font-weight: bold;
          line-height: 16px;
          margin: 0;
        }
      }
    }
  }
}

.show-mobile .my-enquiries {
  margin-top: 34px;
  @media (min-width: 768px) {
    margin-left: 16px;
    margin-right: 16px;
  }
}

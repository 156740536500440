$base-font: Lato;
$text-color-primary: #474747;
$text-color-secondary: #767676;
$text-color-tertiary: #1bace4;

$text-title-color: #004c7f;
$border-color: #cccccc;

@function rem($size) {
  @return $size / 16px * 1rem;
}

.lead-view-popup {
  font-family: $base-font;
  font-size: rem(16px);
  font-style: normal;
  letter-spacing: 0;
  z-index: 99999;

  .modal-dialog {
    max-width: rem(740px);
  }

  .modal-title {
    width: 100%;
  }

  .modal-header {
    padding: 2rem 2rem 1rem 2rem;

    .close {
      margin: 0 0 0 rem(16px);
      padding: 0;
      align-self: flex-start;
    }
  }

  .modal-footer {
    border: none;
  }

  .btn-complete-request {
    border: 2px solid $text-color-tertiary;
    box-sizing: border-box;
    border-radius: 4px;
    color: $text-color-tertiary;
    font-family: $base-font;
    font-size: rem(16px);
    font-style: normal;
    font-weight: 700;
    line-height: rem(22px);
    letter-spacing: 0;
    text-align: center;
  }

  @at-root #{&}__lead-item {
    align-items: flex-start;
    cursor: pointer;
    display: flex;
    background-color: white;
  }

  @at-root #{&}__lead-img {
    width: rem(40px);
  }

  @at-root #{&}__lead-details-wrapper {
    width: 100%;
    margin-left: rem(16px);
  }

  @at-root #{&}__lead-status-wrapper {
    display: flex;
    justify-content: space-between;
    margin: 0;
    width: 100%;
    @media (max-width: 768px) {
      flex-wrap: wrap;
      padding-right: rem(16px);
    }
  }

  @at-root #{&}__lead-title-wrapper {
    display: flex;
    margin: 0;
  }

  @at-root #{&}__lead-name {
    color: $text-color-primary;
    font-size: rem(16px);
    font-weight: bold;
    line-height: rem(22px);
    margin: 0;
    text-transform: uppercase;
  }

  @at-root #{&}__lead-description {
    color: $text-color-secondary;
    font-size: rem(12px);
    font-weight: bold;
    line-height: rem(16px);
    margin: 0;
  }

  @at-root #{&}__lead-category {
    color: $text-color-primary;
    padding-left: 0.25rem;
  }

  @at-root #{&}__header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  @at-root #{&}__info-wrapper {
    display: flex;
    justify-content: space-between;
    padding: rem(4px) rem(14px);
  }

  @at-root #{&}__jobsite-details-wrapper {
    flex: 1;
  }

  @at-root #{&}__jobsite-detail-columns {
    display: flex;
    justify-content: space-between;
    .lead-view-popup__jobsite-detail-item {
      flex: 1;
    }
  }

  @at-root #{&}__documents-wrapper {
    margin-top: rem(16px);
  }

  @at-root #{&}__documents-title {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: rem(16px);
    line-height: rem(22px);
    color: $text-title-color;
    margin: 0;
    border-bottom: 1px solid $border-color;
    padding: 0 0 rem(10px) 0;
  }

  @at-root #{&}__no-documents {
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: bold;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    margin: 10px 0 0 0;
    color: $text-color-primary;
  }
}

.lead-view-popup__request-info-wrapper,
.lead-view-popup__jobsite-info-wrapper {
  flex: 1;
  margin-right: rem(16px);
}

.lead-view-popup__request-info-title,
.lead-view-popup__jobsite-info-title {
  font-family: $base-font;
  font-size: rem(16px);
  font-style: normal;
  font-weight: 700;
  line-height: rem(22px);
  letter-spacing: 0;
  text-align: left;
  color: $text-title-color;
  border-bottom: 1px solid $border-color;
  padding: 0 0 rem(6px) 0;
  margin: 0 0 rem(16px) 0;
}

.lead-view-popup__request-detail-item,
.lead-view-popup__jobsite-detail-item {
  margin-bottom: rem(16px);
}

.lead-view-popup__request-detail-item-label,
.lead-view-popup__jobsite-detail-item-label {
  font-family: $base-font;
  font-size: rem(12px);
  font-style: normal;
  font-weight: 700;
  line-height: rem(16px);
  letter-spacing: 0;
  text-align: left;
  color: $text-color-secondary;
}

.lead-view-popup__request-detail-item-value,
.lead-view-popup__jobsite-detail-item-value {
  font-family: $base-font;
  font-size: rem(16px);
  font-style: normal;
  font-weight: 400;
  line-height: rem(22px);
  letter-spacing: 0;
  text-align: left;
  color: $text-color-primary;
}

.loader-title-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 21px 24px 12px 24px;
}

.loader-row-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 8px 20px 20px 20px;
}

.loader-row {
  display: flex;
  justify-content: center;
  flex: 1 1;
  width: 50%;
  flex-wrap: wrap;
  &.margin-top {
    margin-top: 20px;
  }
}

.site-info-card {
  border: 0;
  border-top: 8px solid #4a90e2;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 1.5rem;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;

  #site-map {
    width: 100%;
    height: 200px;
    margin-bottom: 0;
    .leaflet-container {
      height: 100%;
      width: 100%;
    }
  }

  .site-info-card__title-wrapper {
    margin: 23px 24px 0 23px;
    background-color: transparent;
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
  }

  .site-info-card__title {
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #004c7f;
    margin-bottom: 5px;
    display: inline-block;
  }

  .site-info-card__edit-link {
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: right;
    cursor: pointer;
    color: #1bace4;
  }

  .site-info-card__edit-link-text {
    background-color: transparent;
    border: none;
    color: #1bace4;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: right;
    cursor: pointer;
  }

  .site-info-card__details-wrapper {
    padding: 14px 5px;
    margin: 0;
  }

  .site-info-card__map {
    margin: 0;
  }

  .site-info-card__body-date-duration-label {
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    color: #767676;
    margin: 0 0 4px 0;
  }

  .site-info-card__body-date-duration-text {
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #474747;
    // white-space: nowrap;
    margin-bottom: 16px;
  }

  .site-info-card__body-site-phase-label {
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    color: #767676;
    margin: 0 0 4px 0;
  }

  .site-info-card__body-site-phase-text {
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #474747;
    margin: 0 0 16px 0;
  }

  .site-info-card__body-site-address-label {
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    color: #767676;
    margin: 0 0 4px 0;
  }

  .site-info-card__body-site-time-label {
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    color: #767676;
    margin: 0 0 4px 0;
  }

  .site-info-card__body-site-time-text {
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #474747;
  }
}

.site-overview-call-off-order {
  .my-calloforders {
    margin-left: 0;
    margin-right: 0;
  }
}

.site-documents {
  border: 0;
  border-top: 8px solid #4a90e2;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  padding: 1rem;
  margin-bottom: 1.5rem;

  .request-document-list {
    // max-height: 150px;
    overflow: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    ul {
      margin: 0;
      padding: 0;
    }

    .document-title {
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: left;
      color: #474747;
    }

    li {
      list-style-type: none;
      padding: 5px 0;
      display: flex;
      align-items: center;
      overflow: hidden;
    }
  }
}
